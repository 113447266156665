import { Component } from 'react';
import PropTypes from 'prop-types';
export default class LocaleReceiver extends Component {
    getLocale() {
        const { componentName, defaultLocale } = this.props;
        const { c7nLocale } = this.context;
        const localeFromContext = c7nLocale && c7nLocale[componentName];
        return {
            ...(typeof defaultLocale === 'function' ? defaultLocale() : defaultLocale),
            ...(localeFromContext || {}),
        };
    }
    getLocaleCode() {
        const { c7nLocale } = this.context;
        const localeCode = c7nLocale && c7nLocale.locale;
        // Had use LocaleProvide but didn't set locale
        if (c7nLocale && c7nLocale.exist && !localeCode) {
            return 'en-us';
        }
        return localeCode;
    }
    render() {
        const { children } = this.props;
        return children(this.getLocale(), this.getLocaleCode());
    }
}
LocaleReceiver.contextTypes = {
    c7nLocale: PropTypes.object,
};
