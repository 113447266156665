import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import Icon from '../icon';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class Avatar extends Component {
    constructor(props) {
        super(props);
        this.setScale = () => {
            const childrenNode = this.avatarChildren;
            if (childrenNode) {
                const childrenWidth = childrenNode.offsetWidth;
                const avatarNode = findDOMNode(this);
                const avatarWidth = avatarNode.getBoundingClientRect().width;
                // add 4px gap for each side to get better performance
                if (avatarWidth - 8 < childrenWidth) {
                    this.setState({
                        scale: (avatarWidth - 8) / childrenWidth,
                    });
                }
                else {
                    this.setState({
                        scale: 1,
                    });
                }
            }
        };
        this.handleImgLoadError = () => {
            const { onError } = this.props;
            const errorFlag = onError ? onError() : undefined;
            if (errorFlag !== false) {
                this.setState({ isImgExist: false });
            }
        };
        this.state = {
            scale: 1,
            isImgExist: true,
        };
    }
    componentDidMount() {
        this.setScale();
    }
    componentDidUpdate(prevProps, prevState) {
        const { children } = this.props;
        const { scale, isImgExist } = this.state;
        if (prevProps.children !== children ||
            (prevState.scale !== scale && scale === 1) ||
            prevState.isImgExist !== isImgExist) {
            this.setScale();
        }
    }
    render() {
        const { prefixCls: customizePrefixCls, shape, size, src, icon, className, alt, ...others } = this.props;
        const prefixCls = getPrefixCls('avatar', customizePrefixCls);
        const { isImgExist, scale } = this.state;
        const sizeCls = classNames({
            [`${prefixCls}-lg`]: size === Size.large,
            [`${prefixCls}-sm`]: size === Size.small,
        });
        const classString = classNames(prefixCls, className, sizeCls, {
            [`${prefixCls}-${shape}`]: shape,
            [`${prefixCls}-image`]: src && isImgExist,
            [`${prefixCls}-icon`]: icon,
        });
        const sizeStyle = isNumber(size)
            ? {
                width: size,
                height: size,
                lineHeight: `${size}px`,
                fontSize: icon ? size / 2 : 18,
            }
            : {};
        let { children } = this.props;
        if (src && isImgExist) {
            children = <img src={src} onError={this.handleImgLoadError} alt={alt}/>;
        }
        else if (icon) {
            children = <Icon type={icon}/>;
        }
        else {
            const childrenNode = this.avatarChildren;
            if (childrenNode || scale !== 1) {
                const transformString = `scale(${scale}) translateX(-50%)`;
                const childrenStyle = {
                    msTransform: transformString,
                    WebkitTransform: transformString,
                    transform: transformString,
                };
                const sizeChildrenStyle = isNumber(size)
                    ? {
                        lineHeight: `${size}px`,
                    }
                    : {};
                children = (<span className={`${prefixCls}-string`} ref={span => (this.avatarChildren = span)} style={{ ...sizeChildrenStyle, ...childrenStyle }}>
            {children}
          </span>);
            }
            else {
                children = (<span className={`${prefixCls}-string`} ref={span => (this.avatarChildren = span)}>
            {children}
          </span>);
            }
        }
        return (<span {...others} style={{ ...sizeStyle, ...others.style }} className={classString}>
        {children}
      </span>);
    }
}
Avatar.displayName = 'Avatar';
Avatar.defaultProps = {
    shape: 'circle',
    size: Size.default,
};
