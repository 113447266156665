import React from 'react';
import PropTypes from 'prop-types';
import Radio from './radio';
import { getPrefixCls } from '../configure';
const RadioButton = function (props, context) {
    const { radioGroup } = context;
    const radioProps = { ...props };
    if (radioGroup) {
        radioProps.checked = props.value === radioGroup.value;
        radioProps.disabled = props.disabled || radioGroup.disabled;
    }
    return <Radio prefixCls={getPrefixCls('radio-button')} {...radioProps}/>;
};
RadioButton.displayName = 'RadioButton';
RadioButton.contextTypes = {
    radioGroup: PropTypes.any,
};
export default RadioButton;
