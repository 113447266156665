import React, { Component } from 'react';
import classNames from 'classnames';
import Checkbox from '../checkbox';
import Dropdown from '../dropdown';
import Menu from '../menu';
import Icon from '../icon';
export default class SelectionCheckboxAll extends Component {
    constructor(props) {
        super(props);
        this.handleSelectAllChagne = (e) => {
            const { checked } = e.target;
            const { onSelect } = this.props;
            onSelect(checked ? 'all' : 'removeAll', 0, null);
        };
        this.defaultSelections = props.hideDefaultSelections
            ? []
            : [
                {
                    key: 'all',
                    text: props.locale.selectAll,
                    onSelect: () => { },
                },
                {
                    key: 'invert',
                    text: props.locale.selectInvert,
                    onSelect: () => { },
                },
            ];
        this.state = {
            checked: this.getCheckState(props),
            indeterminate: this.getIndeterminateState(props),
        };
    }
    componentDidMount() {
        this.subscribe();
    }
    componentWillReceiveProps(nextProps) {
        this.setCheckState(nextProps);
    }
    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }
    subscribe() {
        const { store } = this.props;
        this.unsubscribe = store.subscribe(() => {
            this.setCheckState(this.props);
        });
    }
    checkSelection(data, type, byDefaultChecked) {
        const { store, getCheckboxPropsByItem, getRecordKey } = this.props;
        // type should be 'every' | 'some'
        if (type === 'every' || type === 'some') {
            return byDefaultChecked
                ? data[type]((item, i) => getCheckboxPropsByItem(item, i).defaultChecked)
                : data[type]((item, i) => store.getState().selectedRowKeys.indexOf(getRecordKey(item, i)) >= 0);
        }
        return false;
    }
    setCheckState(props) {
        const checked = this.getCheckState(props);
        const { state } = this;
        const indeterminate = this.getIndeterminateState(props);
        if (checked !== state.checked) {
            this.setState({ checked });
        }
        if (indeterminate !== state.indeterminate) {
            this.setState({ indeterminate });
        }
    }
    getCheckState(props) {
        const { store, data } = props;
        let checked;
        if (!data.length) {
            checked = false;
        }
        else {
            checked = store.getState().selectionDirty
                ? this.checkSelection(data, 'every', false)
                : this.checkSelection(data, 'every', false) || this.checkSelection(data, 'every', true);
        }
        return checked;
    }
    getIndeterminateState(props) {
        const { store, data } = props;
        let indeterminate;
        if (!data.length) {
            indeterminate = false;
        }
        else {
            indeterminate = store.getState().selectionDirty
                ? this.checkSelection(data, 'some', false) && !this.checkSelection(data, 'every', false)
                : (this.checkSelection(data, 'some', false) &&
                    !this.checkSelection(data, 'every', false)) ||
                    (this.checkSelection(data, 'some', true) && !this.checkSelection(data, 'every', true));
        }
        return indeterminate;
    }
    renderMenus(selections) {
        const { onSelect } = this.props;
        return selections.map((selection, index) => {
            return (<Menu.Item key={selection.key || index}>
          <div onClick={() => onSelect(selection.key, index, selection.onSelect)}>
            {selection.text}
          </div>
        </Menu.Item>);
        });
    }
    render() {
        const { disabled, prefixCls, selections, getPopupContainer } = this.props;
        const { checked, indeterminate } = this.state;
        const selectionPrefixCls = `${prefixCls}-selection`;
        let customSelections = null;
        if (selections) {
            const newSelections = Array.isArray(selections)
                ? this.defaultSelections.concat(selections)
                : this.defaultSelections;
            const menu = (<Menu className={`${selectionPrefixCls}-menu`} selectedKeys={[]}>
          {this.renderMenus(newSelections)}
        </Menu>);
            customSelections =
                newSelections.length > 0 ? (<Dropdown overlay={menu} getPopupContainer={getPopupContainer}>
            <div className={`${selectionPrefixCls}-down`}>
              <Icon type="expand_more"/>
            </div>
          </Dropdown>) : null;
        }
        return (<div className={selectionPrefixCls}>
        <Checkbox className={classNames({ [`${selectionPrefixCls}-select-all-custom`]: customSelections })} checked={checked} indeterminate={indeterminate} disabled={disabled} onChange={this.handleSelectAllChagne}/>
        {customSelections}
      </div>);
    }
}
