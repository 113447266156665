import React, { Children, cloneElement, isValidElement, PureComponent, } from 'react';
import Animate from '../animate';
import MouseDown from './MouseDown';
export default class RippleChild extends PureComponent {
    constructor() {
        super(...arguments);
        this.handleMouseDown = (child, size) => {
            const { prefixCls } = this.props;
            const { children, style } = child.props;
            const componentProps = {
                className: `${prefixCls}-wrapper`,
            };
            if (size) {
                const { x, y, width, height } = size;
                const maxWidth = Math.max(width - x, x);
                const maxHeight = Math.max(height - y, y);
                const max = Math.sqrt(maxWidth * maxWidth + maxHeight * maxHeight);
                this.currentCircleStyle = {
                    width: max + max,
                    height: max + max,
                    left: x - max,
                    top: y - max,
                };
            }
            const newProps = {
                children: [
                    children,
                    <Animate key="ripple" component="div" componentProps={componentProps} transitionName={size ? 'zoom-small-slow' : 'fade'} hiddenProp="hidden">
          {this.currentCircleStyle && (<div hidden={!size} className={prefixCls} key="circle" style={this.currentCircleStyle}/>)}
        </Animate>,
                ],
                style: this.currentStyle || style,
            };
            if (size && size.position === 'static') {
                newProps.style = { ...style, position: 'relative' };
                this.currentStyle = newProps.style;
            }
            return cloneElement(child, newProps);
        };
        this.ripple = (child) => {
            if (isValidElement(child)) {
                return <MouseDown rippleChild={child}>{this.handleMouseDown}</MouseDown>;
            }
            return child;
        };
    }
    render() {
        const { children } = this.props;
        return this.ripple(Children.only(children));
    }
}
RippleChild.displayName = 'RippleChild';
