export var ProgressType;
(function (ProgressType) {
    ProgressType["line"] = "line";
    ProgressType["circle"] = "circle";
    ProgressType["dashboard"] = "dashboard";
    ProgressType["loading"] = "loading";
})(ProgressType || (ProgressType = {}));
export var ProgressStatus;
(function (ProgressStatus) {
    ProgressStatus["normal"] = "normal";
    ProgressStatus["success"] = "success";
    ProgressStatus["active"] = "active";
    ProgressStatus["exception"] = "exception";
})(ProgressStatus || (ProgressStatus = {}));
export var ProgressPosition;
(function (ProgressPosition) {
    ProgressPosition["top"] = "top";
    ProgressPosition["bottom"] = "bottom";
    ProgressPosition["left"] = "left";
    ProgressPosition["right"] = "right";
})(ProgressPosition || (ProgressPosition = {}));
