import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import noop from 'lodash/noop';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import Header from './Header';
import interopDefault from '../_util/interopDefault';
import enUS from './locale/en_US';
import FullCalendar from '../rc-components/calendar/FullCalendar';
import { getPrefixCls } from '../configure';
export { HeaderProps } from './Header';
function zerofixed(v) {
    if (v < 10) {
        return `0${v}`;
    }
    return `${v}`;
}
export default class Calendar extends Component {
    constructor(props) {
        super(props);
        this.monthCellRender = (value) => {
            const { monthCellRender = noop } = this.props;
            const prefixCls = this.getPrefixCls();
            return (<div className={`${prefixCls}-month`}>
        <div className={`${prefixCls}-value`}>{value.localeData().monthsShort(value)}</div>
        <div className={`${prefixCls}-content`}>{monthCellRender(value)}</div>
      </div>);
        };
        this.dateCellRender = (value) => {
            const { dateCellRender = noop } = this.props;
            const prefixCls = this.getPrefixCls();
            return (<div className={`${prefixCls}-date`}>
        <div className={`${prefixCls}-value`}>{zerofixed(value.date())}</div>
        <div className={`${prefixCls}-content`}>{dateCellRender(value)}</div>
      </div>);
        };
        this.setValue = (value, way) => {
            if (!('value' in this.props)) {
                this.setState({ value });
            }
            if (way === 'select') {
                const { onSelect } = this.props;
                if (onSelect) {
                    onSelect(value);
                }
            }
            else if (way === 'changePanel') {
                const { mode } = this.state;
                this.onPanelChange(value, mode);
            }
        };
        this.setType = (type) => {
            const mode = type === 'date' ? 'month' : 'year';
            const { mode: stateMode, value } = this.state;
            if (stateMode !== mode) {
                this.setState({ mode });
                this.onPanelChange(value, mode);
            }
        };
        this.onHeaderValueChange = (value) => {
            this.setValue(value, 'changePanel');
        };
        this.onHeaderTypeChange = (type) => {
            this.setType(type);
        };
        this.onSelect = (value) => {
            this.setValue(value, 'select');
        };
        this.getDateRange = (validRange, disabledDate) => (current) => {
            if (!current) {
                return false;
            }
            const [startDate, endDate] = validRange;
            const inRange = !current.isBetween(startDate, endDate, 'days', '[]');
            if (disabledDate) {
                return disabledDate(current) || inRange;
            }
            return inRange;
        };
        this.renderCalendar = (locale, localeCode) => {
            const { state, props } = this;
            const { value, mode } = state;
            if (value && localeCode) {
                value.locale(localeCode);
            }
            const { style, className, fullscreen, dateFullCellRender, monthFullCellRender } = props;
            const prefixCls = this.getPrefixCls();
            const type = mode === 'year' ? 'month' : 'date';
            let cls = className || '';
            if (fullscreen) {
                cls += ` ${prefixCls}-fullscreen`;
            }
            const monthCellRender = monthFullCellRender || this.monthCellRender;
            const dateCellRender = dateFullCellRender || this.dateCellRender;
            let disabledDate = props.disabledDate;
            if (props.validRange) {
                disabledDate = this.getDateRange(props.validRange, disabledDate);
            }
            return (<div className={cls} style={style}>
        <Header fullscreen={fullscreen} type={type} value={value} locale={locale.lang} prefixCls={prefixCls} onTypeChange={this.onHeaderTypeChange} onValueChange={this.onHeaderValueChange} validRange={props.validRange}/>
        <FullCalendar {...props} disabledDate={disabledDate} Select={noop} locale={locale.lang} type={type} prefixCls={prefixCls} showHeader={false} value={value} monthCellRender={monthCellRender} dateCellRender={dateCellRender} onSelect={this.onSelect}/>
      </div>);
        };
        const value = props.value || props.defaultValue || interopDefault(moment)();
        if (!interopDefault(moment).isMoment(value)) {
            throw new Error('The value/defaultValue of Calendar must be a moment object');
        }
        this.state = {
            value,
            mode: props.mode,
        };
    }
    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({
                value: nextProps.value,
            });
        }
        const { mode } = this.props;
        if ('mode' in nextProps && nextProps.mode !== mode) {
            this.setState({
                mode: nextProps.mode,
            });
        }
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('fullcalendar', prefixCls);
    }
    onPanelChange(value, mode) {
        const { onPanelChange } = this.props;
        if (onPanelChange) {
            onPanelChange(value, mode);
        }
    }
    render() {
        return (<LocaleReceiver componentName="Calendar" defaultLocale={enUS}>
        {this.renderCalendar}
      </LocaleReceiver>);
    }
}
Calendar.displayName = 'Calendar';
Calendar.defaultProps = {
    locale: {},
    fullscreen: true,
    mode: 'month',
    onSelect: noop,
    onPanelChange: noop,
};
Calendar.propTypes = {
    monthCellRender: PropTypes.func,
    dateCellRender: PropTypes.func,
    monthFullCellRender: PropTypes.func,
    dateFullCellRender: PropTypes.func,
    fullscreen: PropTypes.bool,
    locale: PropTypes.object,
    prefixCls: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onPanelChange: PropTypes.func,
    value: PropTypes.object,
    onSelect: PropTypes.func,
    mode: PropTypes.string,
};
