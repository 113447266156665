import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shallowEqual from 'lodash/isEqual';
import Checkbox from './Checkbox';
import { getPrefixCls } from '../configure';
export default class CheckboxGroup extends Component {
    constructor(props) {
        super(props);
        this.toggleOption = (option) => {
            const { state } = this;
            const optionIndex = state.value.indexOf(option.value);
            const value = [...state.value];
            if (optionIndex === -1) {
                value.push(option.value);
            }
            else {
                value.splice(optionIndex, 1);
            }
            if (!('value' in this.props)) {
                this.setState({ value });
            }
            const { onChange } = this.props;
            if (onChange) {
                onChange(value);
            }
        };
        this.state = {
            value: props.value || props.defaultValue || [],
        };
    }
    getChildContext() {
        const { disabled } = this.props;
        const { value } = this.state;
        return {
            checkboxGroup: {
                toggleOption: this.toggleOption,
                value,
                disabled,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({
                value: nextProps.value || [],
            });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
    }
    getOptions() {
        const { options } = this.props;
        // https://github.com/Microsoft/TypeScript/issues/7960
        return options.map(option => {
            if (typeof option === 'string') {
                return {
                    label: option,
                    value: option,
                };
            }
            return option;
        });
    }
    render() {
        const { props, state } = this;
        const { prefixCls: customizePrefixCls, className, style, options } = props;
        const prefixCls = getPrefixCls('checkbox-group', customizePrefixCls);
        let children = props.children;
        if (options && options.length > 0) {
            children = this.getOptions().map(option => (<Checkbox key={option.value} disabled={'disabled' in option ? option.disabled : props.disabled} value={option.value} checked={state.value.indexOf(option.value) !== -1} onChange={() => this.toggleOption(option)} className={`${prefixCls}-item`}>
          {option.label}
        </Checkbox>));
        }
        const classString = classNames(prefixCls, className);
        const wrapperClassString = classNames({
            [`${prefixCls}-wrapper`]: true,
            [`${prefixCls}-has-label`]: props.label,
        });
        const labelClassString = classNames(`${prefixCls}-label`, {
            'label-disabled': props.disabled,
        });
        return (<div className={wrapperClassString}>
        {props.label ? <span className={labelClassString}>{props.label}</span> : null}
        <div className={classString} style={style}>
          {children}
        </div>
      </div>);
    }
}
CheckboxGroup.displayName = 'CheckboxGroup';
CheckboxGroup.defaultProps = {
    options: [],
};
CheckboxGroup.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
};
CheckboxGroup.childContextTypes = {
    checkboxGroup: PropTypes.any,
};
