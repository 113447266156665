import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import warning from '../_util/warning';
import FormItem from './FormItem';
import { FIELD_DATA_PROP, FIELD_META_PROP } from './constants';
import { createFormField } from '../rc-components/form';
import createDOMForm from '../rc-components/form/createDOMForm';
import PureRenderMixin from '../rc-components/util/PureRenderMixin';
import { FormLayout } from './enum';
import { getPrefixCls } from '../configure';
export default class Form extends Component {
    constructor(props) {
        super(props);
        warning(!props.form, 'It is unnecessary to pass `form` to `Form`');
    }
    shouldComponentUpdate(...args) {
        return PureRenderMixin.shouldComponentUpdate.apply(this, args);
    }
    getChildContext() {
        const { layout } = this.props;
        return {
            vertical: layout === 'vertical',
        };
    }
    render() {
        const { prefixCls: customizePrefixCls, hideRequiredMark, className = '', layout } = this.props;
        const prefixCls = getPrefixCls('form', customizePrefixCls);
        const formClassName = classNames(prefixCls, {
            [`${prefixCls}-${layout}`]: layout,
            [`${prefixCls}-hide-required-mark`]: hideRequiredMark,
        }, className);
        const formProps = omit(this.props, [
            'prefixCls',
            'className',
            'layout',
            'form',
            'hideRequiredMark',
        ]);
        return <form {...formProps} className={formClassName}/>;
    }
}
Form.displayName = 'Form';
Form.defaultProps = {
    layout: FormLayout.horizontal,
    hideRequiredMark: false,
    onSubmit(e) {
        e.preventDefault();
    },
};
Form.propTypes = {
    prefixCls: PropTypes.string,
    layout: PropTypes.oneOf([FormLayout.horizontal, FormLayout.inline, FormLayout.vertical]),
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    hideRequiredMark: PropTypes.bool,
};
Form.childContextTypes = {
    vertical: PropTypes.bool,
};
Form.Item = FormItem;
Form.createFormField = createFormField;
Form.create = function (options = {}) {
    return createDOMForm({
        fieldNameProp: 'id',
        ...options,
        fieldMetaProp: FIELD_META_PROP,
        fieldDataProp: FIELD_DATA_PROP,
    });
};
