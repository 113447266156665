import React, { Component } from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { categories, icons } from 'choerodon-ui-font';
export default class Icon extends Component {
    render() {
        const { type, className = '' } = this.props;
        const classString = classNames('icon', `icon-${type}`, className);
        return <i {...omit(this.props, ['type', 'spin'])} className={classString}/>;
    }
}
Icon.displayName = 'Icon';
Icon.icons = icons;
Icon.categories = categories;
