import { cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
function wrapEvent(element, eventName, callback) {
    return e => {
        const originalEvent = element.props[eventName];
        if (originalEvent) {
            originalEvent(e);
        }
        callback(e);
    };
}
export default class MouseDown extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = {};
        this.show = e => {
            const { currentTarget } = e;
            const pos = currentTarget.getBoundingClientRect();
            this.setState({
                size: {
                    x: e.clientX - pos.left,
                    y: e.clientY - pos.top,
                    width: currentTarget.clientWidth,
                    height: currentTarget.clientHeight,
                    position: document.defaultView && document.defaultView.getComputedStyle(currentTarget).position,
                },
            });
        };
        this.hide = () => {
            this.setState({
                size: undefined,
            });
        };
    }
    render() {
        const { children, rippleChild } = this.props;
        const { size } = this.state;
        const element = children(rippleChild, size);
        const newProps = {
            onMouseDown: wrapEvent(element, 'onMouseDown', this.show),
        };
        if (size) {
            Object.assign(newProps, {
                onMouseUp: wrapEvent(element, 'onMouseUp', this.hide),
                onMouseLeave: wrapEvent(element, 'onMouseLeave', this.hide),
                onDragEnd: wrapEvent(element, 'onDragEnd', this.hide),
            });
        }
        return cloneElement(element, newProps);
    }
}
MouseDown.displayName = 'MouseDown';
MouseDown.propTypes = { rippleChild: PropTypes.node };
