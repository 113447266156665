import React, { Component } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Button from '../button';
import Icon from '../icon';
import Input from '../input';
import warning from '../_util/warning';
import interopDefault from '../_util/interopDefault';
import MonthCalendar from '../rc-components/calendar/MonthCalendar';
import RcDatePicker from '../rc-components/calendar/Picker';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default function createPicker(TheCalendar) {
    var _a;
    return _a = class CalenderWrapper extends Component {
            constructor(props) {
                super(props);
                this.renderFooter = (...args) => {
                    const { renderExtraFooter } = this.props;
                    const prefixCls = this.getPrefixCls();
                    return renderExtraFooter ? (<div className={`${prefixCls}-footer-extra`}>{renderExtraFooter(...args)}</div>) : null;
                };
                this.clearSelection = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleChange(null);
                };
                this.onPickerIconClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const { focused } = this.state;
                    this.picker.setOpen(!focused);
                };
                this.handleChange = (value) => {
                    const props = this.props;
                    if (!('value' in props)) {
                        this.setState({
                            value,
                            showDate: value,
                        });
                    }
                    props.onChange(value, (value && value.format(props.format)) || '');
                };
                this.handleCalendarChange = (value) => {
                    this.setState({ showDate: value });
                };
                this.handleOpenChange = (status) => {
                    const { onOpenChange } = this.props;
                    const { focused } = this.state;
                    if (status !== focused) {
                        this.setState({
                            focused: status,
                        });
                    }
                    if (onOpenChange) {
                        onOpenChange(status);
                    }
                };
                this.saveInput = (node) => {
                    this.input = node;
                };
                this.savePicker = (node) => {
                    this.picker = node;
                };
                const value = props.value || props.defaultValue;
                if (value && !interopDefault(moment).isMoment(value)) {
                    throw new Error('The value/defaultValue of DatePicker or MonthPicker must be a moment object');
                }
                this.state = {
                    value,
                    showDate: value,
                    focused: false,
                };
            }
            componentWillReceiveProps(nextProps) {
                if ('value' in nextProps) {
                    this.setState({
                        value: nextProps.value,
                        showDate: nextProps.value,
                    });
                }
            }
            focus() {
                this.input.focus();
            }
            blur() {
                this.input.blur();
            }
            getPrefixCls() {
                const { prefixCls } = this.props;
                return getPrefixCls('calendar', prefixCls);
            }
            render() {
                const { value, showDate, focused } = this.state;
                const props = omit(this.props, ['onChange']);
                const { label, disabled, pickerInputClass, locale, localeCode } = props;
                const prefixCls = this.getPrefixCls();
                const placeholder = 'placeholder' in props ? props.placeholder : locale.lang.placeholder;
                const disabledTime = props.showTime ? props.disabledTime : null;
                const calendarClassName = classNames({
                    [`${prefixCls}-time`]: props.showTime,
                    [`${prefixCls}-month`]: MonthCalendar === TheCalendar,
                });
                if (value && localeCode) {
                    value.locale(localeCode);
                }
                let pickerProps = {};
                let calendarProps = {};
                if (props.showTime) {
                    calendarProps = {
                        onSelect: this.handleChange,
                    };
                }
                else {
                    pickerProps = {
                        onChange: this.handleChange,
                    };
                }
                if ('mode' in props) {
                    calendarProps.mode = props.mode;
                }
                warning(!('onOK' in props), 'It should be `DatePicker[onOk]` or `MonthPicker[onOk]`, instead of `onOK`!');
                const calendar = (<TheCalendar {...calendarProps} disabledDate={props.disabledDate} disabledTime={disabledTime} locale={locale.lang} timePicker={props.timePicker} defaultValue={props.defaultPickerValue || interopDefault(moment)()} dateInputPlaceholder={placeholder} prefixCls={prefixCls} className={calendarClassName} onOk={props.onOk} dateRender={props.dateRender} format={props.format} showToday={props.showToday} monthCellContentRender={props.monthCellContentRender} renderFooter={this.renderFooter} onPanelChange={props.onPanelChange} onChange={this.handleCalendarChange} value={showDate}/>);
                const clearIcon = !props.disabled && props.allowClear && value ? (<Button className={`${prefixCls}-picker-clear`} onClick={this.clearSelection} shape="circle" icon="close" size={Size.small}/>) : null;
                const suffix = (<span className={`${prefixCls}-picker-icon-wrapper`} onClick={this.onPickerIconClick}>
          {clearIcon}
          <Icon type="date_range" className={`${prefixCls}-picker-icon`}/>
        </span>);
                const inputProps = {
                    label,
                    disabled,
                    placeholder,
                    suffix,
                    focused,
                };
                const input = ({ value: inputValue }) => (<Input {...inputProps} ref={this.saveInput} value={(inputValue && inputValue.format(props.format)) || ''} className={pickerInputClass} readOnly/>);
                return (<span id={props.id} className={classNames(props.className, props.pickerClass)} style={props.style} onFocus={props.onFocus} onBlur={props.onBlur}>
          <RcDatePicker {...props} {...pickerProps} onOpenChange={this.handleOpenChange} calendar={calendar} value={value} prefixCls={`${prefixCls}-picker-container`} style={props.popupStyle} ref={this.savePicker}>
            {input}
          </RcDatePicker>
        </span>);
            }
        },
        _a.displayName = 'CalenderWrapper',
        _a.defaultProps = {
            allowClear: true,
            showToday: true,
        },
        _a;
}
