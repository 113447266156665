import React from 'react';
import classNames from 'classnames';
import RcCollapse from '../rc-components/collapse';
export default function CollapsePanel(props) {
    const { prefixCls, className = '', showArrow = true } = props;
    const collapsePanelClassName = classNames({
        [`${prefixCls}-no-arrow`]: !showArrow,
    }, className);
    return <RcCollapse.Panel {...props} className={collapsePanelClassName}/>;
}
