export default class TaskRunner {
    constructor(fn) {
        this.callbacks = [];
        if (fn) {
            this.fn = fn;
        }
    }
    async delay(delay, fn, callback) {
        return this.start(true, delay, fn, callback);
    }
    async run(interval, fn, callback) {
        return this.start(false, interval, fn, callback);
    }
    async start(once, interval, fn, callback) {
        this.cancel();
        if (fn) {
            this.fn = fn;
        }
        if (typeof callback === 'function') {
            this.callbacks.push(callback);
        }
        if (this.fn) {
            return new Promise(resolve => {
                this.id = setInterval(() => {
                    if (once) {
                        this.cancel();
                    }
                    resolve(this.fn());
                    this.callbacks.forEach(cb => cb());
                    this.callbacks = [];
                }, interval);
            });
        }
        return Promise.reject(new Error('no caller'));
    }
    cancel() {
        if (this.id) {
            clearInterval(this.id);
            delete this.id;
        }
        return this;
    }
}
