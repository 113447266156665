import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import RcRate from '../rc-components/rate';
import { getPrefixCls } from '../configure';
export default class Rate extends Component {
    constructor() {
        super(...arguments);
        this.saveRate = (node) => {
            this.rcRate = node;
        };
    }
    focus() {
        this.rcRate.focus();
    }
    blur() {
        this.rcRate.blur();
    }
    render() {
        return <RcRate ref={this.saveRate} prefixCls={getPrefixCls('rate')} {...this.props}/>;
    }
}
Rate.displayName = 'Rate';
Rate.propTypes = {
    prefixCls: PropTypes.string,
    character: PropTypes.node,
};
Rate.defaultProps = {
    character: <Icon type="star"/>,
};
