import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPrefixCls } from '../configure';
function generator({ displayName, suffixCls }) {
    return (BasicComponent) => {
        var _a;
        return _a = class Adapter extends Component {
                render() {
                    const { prefixCls: customizePrefixCls } = this.props;
                    return (<BasicComponent {...this.props} prefixCls={getPrefixCls(suffixCls, customizePrefixCls)}/>);
                }
            },
            _a.displayName = displayName,
            _a;
    };
}
function Basic(props) {
    const { prefixCls, className, children, ...others } = props;
    const divCls = classNames(className, prefixCls);
    return (<div className={divCls} {...others}>
      {children}
    </div>);
}
class BasicLayout extends Component {
    constructor() {
        super(...arguments);
        this.state = { siders: [] };
    }
    getChildContext() {
        const { siders } = this.state;
        return {
            siderHook: {
                addSider: (id) => {
                    this.setState({
                        siders: [...siders, id],
                    });
                },
                removeSider: (id) => {
                    this.setState({
                        siders: siders.filter(currentId => currentId !== id),
                    });
                },
            },
        };
    }
    render() {
        const { prefixCls, className, children, hasSider, ...others } = this.props;
        const { siders } = this.state;
        const divCls = classNames(className, prefixCls, {
            [`${prefixCls}-has-sider`]: hasSider || siders.length > 0,
        });
        return (<div className={divCls} {...others}>
        {children}
      </div>);
    }
}
BasicLayout.childContextTypes = {
    siderHook: PropTypes.object,
};
const Layout = generator({
    suffixCls: 'layout',
    displayName: 'Layout',
})(BasicLayout);
const Header = generator({
    suffixCls: 'layout-header',
    displayName: 'Header',
})(Basic);
const Footer = generator({
    suffixCls: 'layout-footer',
    displayName: 'Footer',
})(Basic);
const Content = generator({
    suffixCls: 'layout-content',
    displayName: 'Content',
})(Basic);
Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
export default Layout;
