import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPrefixCls } from '../configure';
export default class AnchorLink extends Component {
    constructor() {
        super(...arguments);
        this.handleClick = (e) => {
            const { c7nAnchor: { scrollTo, onClick }, } = this.context;
            const { href, title } = this.props;
            if (onClick) {
                onClick(e, { title, href });
            }
            scrollTo(href);
        };
    }
    componentDidMount() {
        const { c7nAnchor } = this.context;
        const { href } = this.props;
        c7nAnchor.registerLink(href);
    }
    componentDidUpdate({ href: prevHref }) {
        const { href } = this.props;
        if (prevHref !== href) {
            const { c7nAnchor } = this.context;
            c7nAnchor.unregisterLink(prevHref);
            c7nAnchor.registerLink(href);
        }
    }
    componentWillUnmount() {
        const { c7nAnchor } = this.context;
        const { href } = this.props;
        c7nAnchor.unregisterLink(href);
    }
    render() {
        const { c7nAnchor } = this.context;
        const { prefixCls: customizePrefixCls, href, title, children, className } = this.props;
        const prefixCls = getPrefixCls('anchor', customizePrefixCls);
        const active = c7nAnchor.activeLink === href;
        const wrapperClassName = classNames(className, `${prefixCls}-link`, {
            [`${prefixCls}-link-active`]: active,
        });
        const titleClassName = classNames(`${prefixCls}-link-title`, {
            [`${prefixCls}-link-title-active`]: active,
        });
        return (<div className={wrapperClassName}>
        <a className={titleClassName} href={href} title={typeof title === 'string' ? title : ''} onClick={this.handleClick}>
          {title}
        </a>
        {children}
      </div>);
    }
}
AnchorLink.displayName = 'AnchorLink';
AnchorLink.defaultProps = {
    href: '#',
};
AnchorLink.contextTypes = {
    c7nAnchor: PropTypes.object,
};
