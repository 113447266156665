import React, { Children, cloneElement, Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Responsive from '../responsive/Responsive';
import { getPrefixCls } from '../configure';
const defaultGutter = 0;
export default class Row extends Component {
    constructor() {
        super(...arguments);
        this.renderRow = ([gutter = defaultGutter]) => {
            const { type, justify, align, className, style, children, prefixCls: customizePrefixCls, ...others } = this.props;
            const prefixCls = getPrefixCls('row', customizePrefixCls);
            const classes = classNames({
                [prefixCls]: !type,
                [`${prefixCls}-${type}`]: type,
                [`${prefixCls}-${type}-${justify}`]: type && justify,
                [`${prefixCls}-${type}-${align}`]: type && align,
            }, className);
            const rowStyle = gutter > 0
                ? {
                    marginLeft: gutter / -2,
                    marginRight: gutter / -2,
                    ...style,
                }
                : style;
            const cols = Children.map(children, (col) => {
                if (!col) {
                    return null;
                }
                if (col.props && gutter > 0) {
                    return cloneElement(col, {
                        style: {
                            paddingLeft: gutter / 2,
                            paddingRight: gutter / 2,
                            ...col.props.style,
                        },
                    });
                }
                return col;
            });
            const otherProps = { ...others };
            delete otherProps.gutter;
            return (<div {...omit(otherProps, ['rowIndex', 'colIndex'])} className={classes} style={rowStyle}>
        {cols}
      </div>);
        };
    }
    render() {
        const { gutter } = this.props;
        return <Responsive items={[gutter]}>{this.renderRow}</Responsive>;
    }
}
Row.displayName = 'Row';
Row.defaultProps = {
    gutter: defaultGutter,
};
Row.propTypes = {
    type: PropTypes.string,
    align: PropTypes.string,
    justify: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    gutter: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    prefixCls: PropTypes.string,
};
