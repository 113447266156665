import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { getConfirmLocale } from './locale';
import Dialog from '../rc-components/dialog';
import addEventListener from '../_util/addEventListener';
import { getPrefixCls } from '../configure';
let mousePosition;
let mousePositionEventBinded;
export default class Modal extends Component {
    constructor() {
        super(...arguments);
        this.handleCancel = (e) => {
            const { onCancel } = this.props;
            if (onCancel) {
                onCancel(e);
            }
        };
        this.handleOk = (e) => {
            const { onOk } = this.props;
            if (onOk) {
                onOk(e);
            }
        };
        this.renderFooter = (locale) => {
            const { okText, okType, cancelText, confirmLoading, funcType, disableOk, disableCancel, } = this.props;
            return (<div>
        <Button disabled={disableCancel || confirmLoading} onClick={this.handleCancel} funcType={funcType}>
          {cancelText || locale.cancelText}
        </Button>
        <Button type={okType} funcType={funcType} disabled={disableOk} loading={confirmLoading} onClick={this.handleOk}>
          {okText || locale.okText}
        </Button>
      </div>);
        };
    }
    componentDidMount() {
        if (mousePositionEventBinded) {
            return;
        }
        // 只有点击事件支持从鼠标位置动画展开
        addEventListener(document.documentElement, 'click', (e) => {
            mousePosition = {
                x: e.pageX,
                y: e.pageY,
            };
            // 100ms 内发生过点击事件，则从点击位置动画展示
            // 否则直接 zoom 展示
            // 这样可以兼容非点击方式展开
            setTimeout(() => (mousePosition = null), 100);
        });
        mousePositionEventBinded = true;
    }
    render() {
        const { footer, visible, prefixCls: customizePrefixCls } = this.props;
        const prefixCls = getPrefixCls('modal', customizePrefixCls);
        const defaultFooter = (<LocaleReceiver componentName="Modal" defaultLocale={getConfirmLocale()}>
        {this.renderFooter}
      </LocaleReceiver>);
        return (<Dialog {...this.props} prefixCls={prefixCls} footer={footer === undefined ? defaultFooter : footer} visible={visible} mousePosition={mousePosition} onClose={this.handleCancel}/>);
    }
}
Modal.displayName = 'Modal';
Modal.defaultProps = {
    width: 520,
    transitionName: 'zoom',
    maskTransitionName: 'fade',
    confirmLoading: false,
    disableOk: false,
    disableCancel: false,
    visible: false,
    okType: 'primary',
    center: false,
};
Modal.propTypes = {
    prefixCls: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.node,
    cancelText: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    confirmLoading: PropTypes.bool,
    visible: PropTypes.bool,
    align: PropTypes.object,
    footer: PropTypes.node,
    title: PropTypes.node,
    closable: PropTypes.bool,
    transitionName: PropTypes.string,
    funcType: PropTypes.string,
    center: PropTypes.bool,
    disableOk: PropTypes.bool,
    disableCancel: PropTypes.bool,
    okType: PropTypes.string,
    maskTransitionName: PropTypes.string,
};
