import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import Icon from '../icon';
import Animate from '../animate';
import { getPrefixCls } from '../configure';
export default class Alert extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            closing: true,
            closed: false,
        };
        this.handleClose = (e) => {
            e.preventDefault();
            const dom = findDOMNode(this);
            dom.style.height = `${dom.offsetHeight}px`;
            // Magic code
            // 重复一次后才能正确设置 height
            dom.style.height = `${dom.offsetHeight}px`;
            this.setState({
                closing: false,
            });
            const { onClose } = this.props;
            if (onClose) {
                onClose(e);
            }
        };
        this.animationEnd = () => {
            this.setState({
                closed: true,
                closing: true,
            });
            const { afterClose } = this.props;
            if (afterClose) {
                afterClose();
            }
        };
    }
    render() {
        const { props } = this;
        const { description, prefixCls: customizePrefixCls, message, closeText, banner, className = '', style, } = props;
        let { closable, showIcon, type, iconType } = props;
        const { closing, closed } = this.state;
        const prefixCls = getPrefixCls('alert', customizePrefixCls);
        // banner模式默认有 Icon
        showIcon = banner && showIcon === undefined ? true : showIcon;
        // banner模式默认为警告
        type = banner && type === undefined ? 'warning' : type || 'info';
        if (!iconType) {
            switch (type) {
                case 'success':
                    iconType = 'check-circle';
                    break;
                case 'info':
                    iconType = 'info-circle';
                    break;
                case 'error':
                    iconType = 'cross-circle';
                    break;
                case 'warning':
                    iconType = 'exclamation-circle';
                    break;
                default:
                    iconType = 'default';
            }
            // use outline icon in alert with description
            if (description) {
                iconType += '-o';
            }
        }
        const alertCls = classNames(prefixCls, {
            [`${prefixCls}-${type}`]: true,
            [`${prefixCls}-close`]: !closing,
            [`${prefixCls}-with-description`]: !!description,
            [`${prefixCls}-no-icon`]: !showIcon,
            [`${prefixCls}-banner`]: !!banner,
        }, className);
        // closeable when closeText is assigned
        if (closeText) {
            closable = true;
        }
        const closeIcon = closable ? (<a onClick={this.handleClose} className={`${prefixCls}-close-icon`}>
        {closeText || <Icon type="cross"/>}
      </a>) : null;
        return closed ? null : (<Animate component="" hiddenProp="hidden" transitionName={`${prefixCls}-slide-up`} onEnd={this.animationEnd}>
        <div hidden={!closing} className={alertCls} style={style}>
          {showIcon ? <Icon className={`${prefixCls}-icon`} type={iconType}/> : null}
          <span className={`${prefixCls}-message`}>{message}</span>
          <span className={`${prefixCls}-description`}>{description}</span>
          {closeIcon}
        </div>
      </Animate>);
    }
}
Alert.displayName = 'Alert';
