import React, { Component } from 'react';
import classNames from 'classnames';
import { Size } from '../_util/enum';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import Select from '../select';
import MiniSelect from './MiniSelect';
import LargeSelect from './LargeSelect';
import RcPagination from '../rc-components/pagination';
import enUS from '../rc-components/pagination/locale/en_US';
import Button from '../button/Button';
import { getPrefixCls } from '../configure';
function getSelect(size) {
    switch (size) {
        case Size.small:
            return MiniSelect;
        case Size.large:
            return LargeSelect;
        default:
            return Select;
    }
}
function getIcon(type) {
    switch (type) {
        case 'first':
            return 'first_page';
        case 'last':
            return 'last_page';
        case 'prev':
            return 'navigate_before';
        case 'next':
            return 'navigate_next';
        default:
    }
}
function itemRender(page, type, item, disabled, size) {
    if (page !== undefined) {
        if (type === 'page' || type === 'jump-prev' || type === 'jump-next') {
            return (<Button size={size} shape="circle">
          {item}
        </Button>);
        }
        return <Button size={size} shape="circle" icon={getIcon(type)} disabled={disabled}/>;
    }
}
export default class Pagination extends Component {
    constructor() {
        super(...arguments);
        this.renderPagination = (locale) => {
            const { className, size, prefixCls: customizePrefixCls, selectPrefixCls: customizeSelectPrefixCls, ...restProps } = this.props;
            const prefixCls = getPrefixCls('pagination', customizePrefixCls);
            const selectPrefixCls = getPrefixCls('select', customizeSelectPrefixCls);
            return (<RcPagination {...restProps} selectPrefixCls={selectPrefixCls} prefixCls={prefixCls} size={size} className={classNames(className, { [`${prefixCls}-${size}`]: size })} selectComponentClass={getSelect(size)} locale={locale}/>);
        };
    }
    render() {
        return (<LocaleReceiver componentName="Pagination" defaultLocale={enUS}>
        {this.renderPagination}
      </LocaleReceiver>);
    }
}
Pagination.displayName = 'Pagination';
Pagination.defaultProps = {
    showSizeChanger: true,
    showSizeChangerLabel: true,
    tiny: true,
    pageSizeOptions: ['10', '30', '50', '100', '200'],
    showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
    sizeChangerOptionText: (value) => value,
    itemRender,
};
