import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shallowEqual from 'lodash/isEqual';
import RcCheckbox from '../rc-components/checkbox';
import { getPrefixCls } from '../configure';
export default class Checkbox extends Component {
    constructor() {
        super(...arguments);
        this.saveCheckbox = (node) => {
            this.rcCheckbox = node;
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { checkboxGroup } = this.context;
        return (!shallowEqual(this.props, nextProps) ||
            !shallowEqual(this.state, nextState) ||
            !shallowEqual(checkboxGroup, nextContext.checkboxGroup));
    }
    focus() {
        this.rcCheckbox.focus();
    }
    blur() {
        this.rcCheckbox.blur();
    }
    render() {
        const { props, context } = this;
        const { prefixCls: customizePrefixCls, className, children, indeterminate, style, onMouseEnter, onMouseLeave, ...restProps } = props;
        const prefixCls = getPrefixCls('checkbox', customizePrefixCls);
        const { checkboxGroup } = context;
        const checkboxProps = { ...restProps };
        if (checkboxGroup) {
            checkboxProps.onChange = () => checkboxGroup.toggleOption({ label: children, value: props.value });
            checkboxProps.checked = checkboxGroup.value.indexOf(props.value) !== -1;
            checkboxProps.disabled = props.disabled || checkboxGroup.disabled;
        }
        const classString = classNames(className, {
            [`${prefixCls}-wrapper`]: true,
        });
        const checkboxClass = classNames({
            [`${prefixCls}-indeterminate`]: indeterminate,
        });
        return (<label className={classString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <RcCheckbox {...checkboxProps} prefixCls={prefixCls} className={checkboxClass} ref={this.saveCheckbox}/>
        {children !== undefined ? <span>{children}</span> : null}
      </label>);
    }
}
Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
    indeterminate: false,
};
Checkbox.contextTypes = {
    checkboxGroup: PropTypes.any,
};
