import React, { Children, Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import warning from '../_util/warning';
import { FIELD_DATA_PROP, FIELD_META_PROP } from './constants';
import PureRenderMixin from '../rc-components/util/PureRenderMixin';
import Animate from '../animate';
import { FormItemValidateStatus } from './enum';
import { getPrefixCls } from '../configure';
export default class FormItem extends Component {
    constructor() {
        super(...arguments);
        this.state = { helpShow: false };
        this.onHelpAnimEnd = (_key, helpShow) => {
            this.setState({ helpShow });
        };
        // Resolve duplicated ids bug between different forms
        this.onLabelClick = (e) => {
            const { label, id: propId } = this.props;
            const id = propId || this.getId();
            if (!id) {
                return;
            }
            const controls = document.querySelectorAll(`[id="${id}"]`);
            if (controls.length !== 1) {
                // Only prevent in default situation
                // Avoid preventing event in `label={<a href="xx">link</a>}``
                if (typeof label === 'string') {
                    e.preventDefault();
                }
                const control = findDOMNode(this).querySelector(`[id="${id}"]`);
                if (control && control.focus) {
                    control.focus();
                }
            }
        };
    }
    componentDidMount() {
        const { children } = this.props;
        warning(this.getControls(children, true).length <= 1, '`Form.Item` cannot generate `validateStatus` and `help` automatically, ' +
            'while there are more than one `getFieldDecorator` in it.');
    }
    shouldComponentUpdate(...args) {
        return PureRenderMixin.shouldComponentUpdate.apply(this, args);
    }
    getHelpMsg() {
        const props = this.props;
        const onlyControl = this.getOnlyControl();
        if (props.help === undefined && onlyControl) {
            const errors = this.getField().errors;
            return errors ? errors.map((e) => e.message).join(', ') : '';
        }
        return props.help;
    }
    getControls(children, recursively) {
        let controls = [];
        const childrenArray = Children.toArray(children);
        for (let i = 0; i < childrenArray.length; i++) {
            if (!recursively && controls.length > 0) {
                break;
            }
            const child = childrenArray[i];
            if (child.type &&
                (child.type === FormItem || child.type.displayName === 'FormItem')) {
                continue;
            }
            if (!child.props) {
                continue;
            }
            if (FIELD_META_PROP in child.props) {
                // And means FIELD_DATA_PROP in chidl.props, too.
                controls.push(child);
            }
            else if (child.props.children) {
                controls = controls.concat(this.getControls(child.props.children, recursively));
            }
        }
        return controls;
    }
    getOnlyControl() {
        const { children } = this.props;
        const child = this.getControls(children, false)[0];
        return child !== undefined ? child : null;
    }
    getChildProp(prop) {
        const child = this.getOnlyControl();
        return child && child.props && child.props[prop];
    }
    getId() {
        return this.getChildProp('id');
    }
    getMeta() {
        return this.getChildProp(FIELD_META_PROP);
    }
    getField() {
        return this.getChildProp(FIELD_DATA_PROP);
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('form', prefixCls);
    }
    renderHelp() {
        const prefixCls = this.getPrefixCls();
        const help = this.getHelpMsg();
        const children = help ? (<div className={`${prefixCls}-explain`} key="error">
        {help}
      </div>) : null;
        return (<Animate transitionName="show-error" component="" transitionAppear key="error" onEnd={this.onHelpAnimEnd}>
        {children}
      </Animate>);
    }
    renderExtra() {
        const { extra } = this.props;
        const prefixCls = this.getPrefixCls();
        return extra ? <div className={`${prefixCls}-extra`}>{extra}</div> : null;
    }
    getValidateStatus() {
        const onlyControl = this.getOnlyControl();
        if (onlyControl) {
            const field = this.getField();
            if (field.validating) {
                return FormItemValidateStatus.validating;
            }
            if (field.errors) {
                return FormItemValidateStatus.error;
            }
            const fieldValue = 'value' in field ? field.value : this.getMeta().initialValue;
            if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
                return FormItemValidateStatus.success;
            }
        }
    }
    renderValidateWrapper(c1, c2, c3) {
        const props = this.props;
        const prefixCls = this.getPrefixCls();
        const onlyControl = this.getOnlyControl();
        const validateStatus = props.validateStatus === undefined && onlyControl
            ? this.getValidateStatus()
            : props.validateStatus;
        let classes = `${prefixCls}-item-control`;
        if (validateStatus) {
            classes = classNames(`${prefixCls}-item-control`, {
                'has-feedback': props.hasFeedback || validateStatus === FormItemValidateStatus.validating,
                'has-success': validateStatus === FormItemValidateStatus.success,
                'has-warning': validateStatus === FormItemValidateStatus.warning,
                'has-error': validateStatus === FormItemValidateStatus.error,
                'is-validating': validateStatus === FormItemValidateStatus.validating,
            });
        }
        return (<div className={classes}>
        <span className={`${prefixCls}-item-children`}>{c1}</span>
        {c2}
        {c3}
      </div>);
    }
    renderWrapper(children) {
        const { wrapperCol } = this.props;
        const prefixCls = this.getPrefixCls();
        const required = this.isRequired();
        const className = classNames(`${prefixCls}-item-control-wrapper`, wrapperCol && wrapperCol.className, {
            'is-required': required,
        });
        return (<div className={className} key="wrapper">
        {children}
      </div>);
    }
    isRequired() {
        const { required } = this.props;
        if (required !== undefined) {
            return required;
        }
        if (this.getOnlyControl()) {
            const meta = this.getMeta() || {};
            const validate = meta.validate || [];
            return validate
                .filter((item) => !!item.rules)
                .some((item) => {
                return item.rules.some((rule) => rule.required);
            });
        }
        return false;
    }
    renderChildren() {
        const { children } = this.props;
        return [
            // this.renderLabel(),
            this.renderWrapper(this.renderValidateWrapper(children, this.renderHelp(), this.renderExtra())),
        ];
    }
    renderFormItem(children) {
        const props = this.props;
        const { helpShow } = this.state;
        const prefixCls = this.getPrefixCls();
        const style = props.style;
        const itemClassName = {
            [`${prefixCls}-item`]: true,
            [`${prefixCls}-item-with-help`]: !!this.getHelpMsg() || helpShow,
            [`${prefixCls}-item-no-colon`]: !props.colon,
            [`${props.className}`]: !!props.className,
        };
        return (<div className={classNames(itemClassName)} style={style}>
        {children}
      </div>);
    }
    render() {
        const children = this.renderChildren();
        return this.renderFormItem(children);
    }
}
FormItem.displayName = 'FormItem';
FormItem.defaultProps = {
    hasFeedback: false,
    colon: true,
};
FormItem.propTypes = {
    prefixCls: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    validateStatus: PropTypes.oneOf([
        FormItemValidateStatus.success,
        FormItemValidateStatus.warning,
        FormItemValidateStatus.error,
        FormItemValidateStatus.validating,
    ]),
    hasFeedback: PropTypes.bool,
    wrapperCol: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node,
    colon: PropTypes.bool,
};
FormItem.contextTypes = {
    vertical: PropTypes.bool,
};
