import React, { Component } from 'react';
import Tooltip from '../tooltip';
import RcSlider, { Handle as RcHandle, Range as RcRange } from '../rc-components/slider';
import { getPrefixCls } from '../configure';
export default class Slider extends Component {
    constructor(props) {
        super(props);
        this.toggleTooltipVisible = (index, visible) => {
            this.setState(({ visibles }) => ({
                visibles: {
                    ...visibles,
                    [index]: visible,
                },
            }));
        };
        this.handleWithTooltip = ({ value, dragging, index, ...restProps }) => {
            const { tooltipPrefixCls: customizeTooltipPrefixCls, tipFormatter } = this.props;
            const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
            const { visibles } = this.state;
            const visible = tipFormatter ? visibles[index] || dragging : false;
            return (<Tooltip prefixCls={tooltipPrefixCls} title={tipFormatter ? tipFormatter(value) : ''} visible={visible} placement="top" transitionName="zoom-down" key={index}>
        <RcHandle {...restProps} value={value} onMouseEnter={() => this.toggleTooltipVisible(index, true)} onMouseLeave={() => this.toggleTooltipVisible(index, false)}/>
      </Tooltip>);
        };
        this.saveSlider = (node) => {
            this.rcSlider = node;
        };
        this.state = {
            visibles: {},
        };
    }
    focus() {
        this.rcSlider.focus();
    }
    blur() {
        this.rcSlider.focus();
    }
    render() {
        const { range, prefixCls: customizePrefixCls, ...restProps } = this.props;
        const prefixCls = getPrefixCls('slider', customizePrefixCls);
        if (range) {
            return (<RcRange {...restProps} ref={this.saveSlider} handle={this.handleWithTooltip} prefixCls={prefixCls}/>);
        }
        return (<RcSlider {...restProps} ref={this.saveSlider} handle={this.handleWithTooltip} prefixCls={prefixCls}/>);
    }
}
Slider.displayName = 'Slider';
Slider.defaultProps = {
    tipFormatter(value) {
        return value.toString();
    },
};
