import React, { Component } from 'react';
import classNames from 'classnames';
import animation from '../_util/openAnimation';
import CollapsePanel from './CollapsePanel';
import RcCollapse from '../rc-components/collapse';
import { getPrefixCls } from '../configure';
export default class Collapse extends Component {
    render() {
        const { prefixCls: customizePrefixCls, className = '', bordered } = this.props;
        const prefixCls = getPrefixCls('collapse', customizePrefixCls);
        const collapseClassName = classNames({
            [`${prefixCls}-borderless`]: !bordered,
        }, className);
        return <RcCollapse {...this.props} prefixCls={prefixCls} className={collapseClassName}/>;
    }
}
Collapse.displayName = 'Collapse';
Collapse.Panel = CollapsePanel;
Collapse.defaultProps = {
    bordered: true,
    openAnimation: {
        ...animation,
        appear() { },
    },
};
