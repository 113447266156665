import React, { cloneElement, Component, } from 'react';
import classNames from 'classnames';
import animation from '../_util/openAnimation';
import RcTree, { TreeNode } from '../rc-components/tree';
import Icon from '../icon';
import Progress from '../progress';
import { ProgressType } from '../progress/enum';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export { TreeNode };
export default class Tree extends Component {
    constructor() {
        super(...arguments);
        this.renderSwitcherIcon = ({ isLeaf, loading }) => {
            const { showLine, switcherIcon } = this.props;
            const prefixCls = this.getPrefixCls();
            if (loading) {
                return (<Progress type={ProgressType.loading} className={`${prefixCls}-switcher-loading-icon`} size={Size.small}/>);
            }
            const switcherCls = `${prefixCls}-switcher-icon`;
            if (showLine) {
                if (isLeaf) {
                    return <Icon type="note" className={`${prefixCls}-switcher-line-icon`}/>;
                }
                return <Icon type="arrow_drop_down" className={switcherCls}/>;
            }
            if (isLeaf) {
                return null;
            }
            if (switcherIcon) {
                const switcherOriginCls = switcherIcon.props.className || '';
                return cloneElement(switcherIcon, {
                    className: [switcherOriginCls, switcherCls],
                });
            }
            return <Icon type="arrow_drop_down" className={switcherCls}/>;
        };
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('tree', prefixCls);
    }
    render() {
        const props = this.props;
        const { className, showIcon, checkable, children } = props;
        const prefixCls = this.getPrefixCls();
        return (<RcTree {...props} className={classNames(!showIcon && `${prefixCls}-icon-hide`, className)} checkable={checkable ? <span className={`${prefixCls}-checkbox-inner`}/> : checkable} switcherIcon={this.renderSwitcherIcon} prefixCls={prefixCls}>
        {children}
      </RcTree>);
    }
}
Tree.displayName = 'Tree';
Tree.TreeNode = TreeNode;
Tree.defaultProps = {
    checkable: false,
    showIcon: false,
    openAnimation: animation,
};
