import React, { Component, } from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';
import calculateNodeHeight from './calculateNodeHeight';
import { getPrefixCls } from '../configure';
function onNextFrame(cb) {
    if (window.requestAnimationFrame) {
        return window.requestAnimationFrame(cb);
    }
    return window.setTimeout(cb, 1);
}
function clearNextFrameAction(nextFrameId) {
    if (window.cancelAnimationFrame) {
        window.cancelAnimationFrame(nextFrameId);
    }
    else {
        window.clearTimeout(nextFrameId);
    }
}
export default class TextArea extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            textareaStyles: {},
            inputLength: 0,
            focused: false,
        };
        this.resizeTextarea = () => {
            const { autosize } = this.props;
            if (!autosize || !this.textAreaRef) {
                return;
            }
            const minRows = autosize ? autosize.minRows : null;
            const maxRows = autosize ? autosize.maxRows : null;
            const textareaStyles = calculateNodeHeight(this.textAreaRef, false, minRows, maxRows);
            this.setState({ textareaStyles });
        };
        this.handleTextareaChange = (e) => {
            if (!('value' in this.props)) {
                this.resizeTextarea();
            }
            const { onChange } = this.props;
            if (onChange) {
                onChange(e);
            }
        };
        this.handleKeyDown = (e) => {
            const { onPressEnter, onKeyDown } = this.props;
            if (e.keyCode === 13 && onPressEnter) {
                onPressEnter(e);
            }
            if (onKeyDown) {
                onKeyDown(e);
            }
        };
        this.handleInput = () => {
            this.setState({
                inputLength: this.textAreaRef.value.length,
            });
        };
        this.saveTextAreaRef = (textArea) => {
            this.textAreaRef = textArea;
        };
        this.handleFocus = (e) => {
            const { onFocus } = this.props;
            this.setState({
                focused: true,
            });
            if (onFocus) {
                onFocus(e);
            }
        };
        this.handleBlur = (e) => {
            const { onBlur } = this.props;
            this.setState({
                focused: false,
            });
            if (onBlur) {
                onBlur(e);
            }
        };
    }
    componentDidMount() {
        this.resizeTextarea();
        if (this.textAreaRef.value) {
            this.setState({
                inputLength: this.textAreaRef.value.length,
            });
        }
        const { autoFocus } = this.props;
        if (autoFocus) {
            this.setState({
                focused: true,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        // Re-render with the new content then recalculate the height as required.
        if (this.textAreaRef.value !== nextProps.value) {
            const inputLength = nextProps.value && nextProps.value.length;
            this.setState({
                inputLength: inputLength || 0,
            });
        }
        if (nextProps.autoFocus) {
            this.setState({
                focused: true,
            });
        }
        const { value } = this.props;
        if (value !== nextProps.value) {
            if (this.nextFrameActionId) {
                clearNextFrameAction(this.nextFrameActionId);
            }
            this.nextFrameActionId = onNextFrame(this.resizeTextarea);
        }
    }
    focus() {
        this.textAreaRef.focus();
    }
    blur() {
        this.textAreaRef.blur();
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('input', prefixCls);
    }
    getTextAreaClassName() {
        const { className } = this.props;
        const prefixCls = this.getPrefixCls();
        return classNames(prefixCls, `${prefixCls}-textarea-element`, className);
    }
    getWrapperClassName() {
        const { disabled, label, border } = this.props;
        const { inputLength, focused } = this.state;
        const prefixCls = this.getPrefixCls();
        return classNames(`${prefixCls}-wrapper`, `${prefixCls}-textarea`, {
            [`${prefixCls}-has-value`]: inputLength !== 0,
            [`${prefixCls}-focused`]: focused,
            [`${prefixCls}-disabled`]: disabled,
            [`${prefixCls}-has-label`]: !!label,
            [`${prefixCls}-has-border`]: border,
        });
    }
    getLengthInfo() {
        const { maxLength, showLengthInfo } = this.props;
        const prefixCls = this.getPrefixCls();
        const { inputLength } = this.state;
        return (maxLength && showLengthInfo) ||
            (maxLength && maxLength > 0 && inputLength === maxLength) ? (<div className={`${prefixCls}-length-info`}>{`${inputLength}/${maxLength}`}</div>) : null;
    }
    getLabel() {
        const { placeholder, label } = this.props;
        const { inputLength } = this.state;
        if (inputLength === 0 && placeholder) {
            return placeholder;
        }
        return label;
    }
    renderFloatLabel() {
        const label = this.getLabel();
        if (label) {
            const prefixCls = this.getPrefixCls();
            return (<div className={`${prefixCls}-label-wrapper`}>
          <div className={`${prefixCls}-label`}>{label}</div>
        </div>);
        }
    }
    render() {
        const props = this.props;
        const { textareaStyles } = this.state;
        const prefixCls = this.getPrefixCls();
        const otherProps = omit(props, [
            'prefixCls',
            'onPressEnter',
            'autosize',
            'placeholder',
            'focused',
            'showLengthInfo',
        ]);
        const style = {
            ...props.style,
            ...textareaStyles,
        };
        // Make sure it could be reset when using form.getFieldDecorator
        if ('value' in otherProps) {
            otherProps.value = otherProps.value || '';
        }
        otherProps.onInput = this.handleInput;
        return (<span className={this.getWrapperClassName()}>
        <div className={`${prefixCls}-rendered-wrapper`}>
          <textarea {...otherProps} className={this.getTextAreaClassName()} style={style} onKeyDown={this.handleKeyDown} onChange={this.handleTextareaChange} ref={this.saveTextAreaRef} onInput={this.handleInput} onBlur={this.handleBlur} onFocus={this.handleFocus}/>
          {this.renderFloatLabel()}
        </div>
        {this.getLengthInfo()}
      </span>);
    }
}
TextArea.displayName = 'TextArea';
TextArea.defaultProps = {
    showLengthInfo: true,
    border: true,
};
