import React, { Component } from 'react';
import Button from '../button/Button';
import SelectTrigger from '../rc-components/select/SelectTrigger';
import { Item as MenuItem } from '../rc-components/menu';
import { UNSELECTABLE_ATTRIBUTE, UNSELECTABLE_STYLE } from '../rc-components/select/util';
import { getColumnKey } from './util';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class ColumnFilter extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            open: false,
        };
        this.onMenuSelect = (item) => {
            item.item.props.value.hidden = false;
            this.fireChange(item);
        };
        this.onMenuDeselect = (item) => {
            item.item.props.value.hidden = true;
            this.fireChange(item);
        };
        this.onDropdownVisibleChange = (open) => {
            const { state } = this;
            if (state.open !== open) {
                this.setState({
                    open,
                });
            }
        };
    }
    render() {
        const { prefixCls, getPopupContainer } = this.props;
        const { open } = this.state;
        return (<div className={`${prefixCls}-columns-chooser`}>
        <SelectTrigger prefixCls={getPrefixCls('select')} showAction={['click']} options={this.getOptions()} value={this.getVisibleColumns()} getPopupContainer={getPopupContainer} multiple onDropdownVisibleChange={this.onDropdownVisibleChange} onMenuSelect={this.onMenuSelect} onMenuDeselect={this.onMenuDeselect} visible={open} popupPlacement="bottomRight" dropdownMatchSelectWidth={false} dropdownStyle={{ minWidth: 187 }}>
          <Button shape="circle" icon="view_column" size={Size.small}/>
        </SelectTrigger>
      </div>);
    }
    fireChange(item) {
        const { onColumnFilterChange } = this.props;
        if (onColumnFilterChange) {
            onColumnFilterChange(item);
        }
    }
    getOptions() {
        const { columns } = this.props;
        const options = [];
        (columns || []).forEach((column, i) => {
            const { title, notDisplay, disableClick } = column;
            if (title && !notDisplay) {
                options.push(<MenuItem disabled={disableClick} style={UNSELECTABLE_STYLE} attribute={UNSELECTABLE_ATTRIBUTE} value={column} key={getColumnKey(column, i)}>
            {title}
          </MenuItem>);
            }
        });
        return options;
    }
    getVisibleColumns() {
        const { columns } = this.props;
        return (columns || []).filter(column => !column.hidden);
    }
}
ColumnFilter.displayName = 'ColumnFilter';
