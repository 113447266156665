export var TabsType;
(function (TabsType) {
    TabsType["line"] = "line";
    TabsType["card"] = "card";
    TabsType["editable-card"] = "editable-card";
})(TabsType || (TabsType = {}));
export var TabsPosition;
(function (TabsPosition) {
    TabsPosition["top"] = "top";
    TabsPosition["bottom"] = "bottom";
    TabsPosition["left"] = "left";
    TabsPosition["right"] = "right";
})(TabsPosition || (TabsPosition = {}));
