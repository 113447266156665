import React, { Children, Component, isValidElement, } from 'react';
import classNames from 'classnames';
import Select from '../select';
import Input from '../input';
import InputElement from './InputElement';
import { OptGroup, Option } from '../rc-components/select';
import { Size } from '../_util/enum';
import { SelectMode } from '../select/enum';
import { getPrefixCls } from '../configure';
function isSelectOptionOrSelectOptGroup(child) {
    return child && child.type && (child.type.isSelectOption || child.type.isSelectOptGroup);
}
export default class AutoComplete extends Component {
    constructor() {
        super(...arguments);
        this.getInputElement = () => {
            const { children } = this.props;
            const element = children && isValidElement(children) && children.type !== Option ? (Children.only(children)) : (<Input border={false}/>);
            const elementProps = { ...element.props };
            delete elementProps.children;
            return <InputElement {...elementProps}>{element}</InputElement>;
        };
        this.saveSelect = (node) => {
            this.select = node;
        };
    }
    focus() {
        this.select.focus();
    }
    blur() {
        this.select.blur();
    }
    render() {
        const { size, className = '', notFoundContent, prefixCls: customizePrefixCls, optionLabelProp, dataSource, children, } = this.props;
        const prefixCls = getPrefixCls('select', customizePrefixCls);
        const cls = classNames({
            [`${prefixCls}-lg`]: size === Size.large,
            [`${prefixCls}-sm`]: size === Size.small,
            [className]: !!className,
            [`${prefixCls}-show-search`]: true,
            [`${prefixCls}-auto-complete`]: true,
        });
        let options;
        const childArray = Children.toArray(children);
        if (childArray.length && isSelectOptionOrSelectOptGroup(childArray[0])) {
            options = children;
        }
        else {
            options = dataSource
                ? dataSource.map(item => {
                    if (isValidElement(item)) {
                        return item;
                    }
                    switch (typeof item) {
                        case 'string':
                            return <Option key={item}>{item}</Option>;
                        case 'object':
                            return (<Option key={item.value}>
                    {item.text}
                  </Option>);
                        default:
                            throw new Error('AutoComplete[dataSource] only supports type `string[] | Object[]`.');
                    }
                })
                : [];
        }
        return (<Select {...this.props} prefixCls={prefixCls} className={cls} mode={SelectMode.combobox} optionLabelProp={optionLabelProp} getInputElement={this.getInputElement} notFoundContent={notFoundContent} ref={this.saveSelect}>
        {options}
      </Select>);
    }
}
AutoComplete.displayName = 'AutoComplete';
AutoComplete.Option = Option;
AutoComplete.OptGroup = OptGroup;
AutoComplete.defaultProps = {
    transitionName: 'slide-up',
    optionLabelProp: 'children',
    choiceTransitionName: 'zoom',
    showSearch: false,
    filterOption: false,
};
