import React from 'react';
import classNames from 'classnames';
import { getPrefixCls } from '../configure';
const Grid = (props) => {
    const { prefixCls: customizePrefixCls, className, ...others } = props;
    const prefixCls = getPrefixCls('card', customizePrefixCls);
    const classString = classNames(`${prefixCls}-grid`, className);
    return <div {...others} className={classString}/>;
};
Grid.displayName = 'CardGrid';
export default Grid;
