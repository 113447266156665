export var DataSetEvents;
(function (DataSetEvents) {
    DataSetEvents["query"] = "query";
    DataSetEvents["beforeLoad"] = "beforeLoad";
    DataSetEvents["load"] = "load";
    DataSetEvents["loadFailed"] = "loadFailed";
    DataSetEvents["submit"] = "submit";
    DataSetEvents["submitSuccess"] = "submitSuccess";
    DataSetEvents["submitFailed"] = "submitFailed";
    DataSetEvents["select"] = "select";
    DataSetEvents["unSelect"] = "unSelect";
    DataSetEvents["selectAll"] = "selectAll";
    DataSetEvents["unSelectAll"] = "unSelectAll";
    DataSetEvents["indexChange"] = "indexChange";
    DataSetEvents["update"] = "update";
    DataSetEvents["fieldChange"] = "fieldChange";
    DataSetEvents["export"] = "export";
    DataSetEvents["create"] = "create";
    DataSetEvents["beforeDelete"] = "beforeDelete";
    DataSetEvents["reset"] = "reset";
    DataSetEvents["recordReset"] = "recordReset";
})(DataSetEvents || (DataSetEvents = {}));
export var DataSetSelection;
(function (DataSetSelection) {
    DataSetSelection["single"] = "single";
    DataSetSelection["multiple"] = "multiple";
})(DataSetSelection || (DataSetSelection = {}));
export var DataSetStatus;
(function (DataSetStatus) {
    DataSetStatus["loading"] = "loading";
    DataSetStatus["submitting"] = "submitting";
    DataSetStatus["ready"] = "ready";
})(DataSetStatus || (DataSetStatus = {}));
export var RecordStatus;
(function (RecordStatus) {
    RecordStatus["delete"] = "delete";
    RecordStatus["update"] = "update";
    RecordStatus["add"] = "add";
    RecordStatus["sync"] = "sync";
})(RecordStatus || (RecordStatus = {}));
export var FieldType;
(function (FieldType) {
    FieldType["auto"] = "auto";
    FieldType["boolean"] = "boolean";
    FieldType["number"] = "number";
    FieldType["currency"] = "currency";
    FieldType["string"] = "string";
    FieldType["date"] = "date";
    FieldType["dateTime"] = "dateTime";
    FieldType["week"] = "week";
    FieldType["month"] = "month";
    FieldType["year"] = "year";
    FieldType["time"] = "time";
    FieldType["object"] = "object";
    FieldType["intl"] = "intl";
    FieldType["email"] = "email";
    FieldType["url"] = "url";
    FieldType["color"] = "color";
    FieldType["reactNode"] = "reactNode";
})(FieldType || (FieldType = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder || (SortOrder = {}));
export var BooleanValue;
(function (BooleanValue) {
    BooleanValue["trueValue"] = "trueValue";
    BooleanValue["falseValue"] = "falseValue";
})(BooleanValue || (BooleanValue = {}));
export var FieldIgnore;
(function (FieldIgnore) {
    FieldIgnore["always"] = "always";
    FieldIgnore["clean"] = "clean";
    FieldIgnore["never"] = "never";
})(FieldIgnore || (FieldIgnore = {}));
export var FieldTrim;
(function (FieldTrim) {
    FieldTrim["both"] = "both";
    FieldTrim["left"] = "left";
    FieldTrim["right"] = "right";
    FieldTrim["none"] = "none";
})(FieldTrim || (FieldTrim = {}));
export var FieldFormat;
(function (FieldFormat) {
    FieldFormat["uppercase"] = "uppercase";
    FieldFormat["lowercase"] = "lowercase";
    FieldFormat["capitalize"] = "capitalize";
})(FieldFormat || (FieldFormat = {}));
