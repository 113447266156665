import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Icon from '../icon';
import CheckableTag from './CheckableTag';
import Animate from '../animate';
import { getPrefixCls } from '../configure';
export { CheckableTagProps } from './CheckableTag';
export default class Tag extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            closing: false,
            closed: false,
        };
        this.close = e => {
            const { onClose } = this.props;
            if (onClose) {
                onClose(e);
            }
            if (e.defaultPrevented) {
                return;
            }
            const dom = findDOMNode(this);
            dom.style.width = `${dom.getBoundingClientRect().width}px`;
            // It's Magic Code, don't know why
            dom.style.width = `${dom.getBoundingClientRect().width}px`;
            this.setState({
                closing: true,
            });
        };
        this.animationEnd = (_, existed) => {
            const { closed } = this.state;
            if (!existed && !closed) {
                this.setState({
                    closed: true,
                    closing: false,
                });
                const { afterClose } = this.props;
                if (afterClose) {
                    afterClose();
                }
            }
        };
    }
    isPresetColor(color) {
        if (!color) {
            return false;
        }
        return /^(pink|red|yellow|orange|cyan|green|blue|purple|geekblue|magenta|volcano|gold|lime)(-inverse)?$/.test(color);
    }
    render() {
        const { prefixCls: customizePrefixCls, closable, color, className, children, style, ...otherProps } = this.props;
        const prefixCls = getPrefixCls('tag', customizePrefixCls);
        const { closing, closed } = this.state;
        const closeIcon = closable ? <Icon type="close" onClick={this.close}/> : '';
        const isPresetColor = this.isPresetColor(color);
        const classString = classNames(prefixCls, {
            [`${prefixCls}-${color}`]: isPresetColor,
            [`${prefixCls}-has-color`]: color && !isPresetColor,
            [`${prefixCls}-close`]: closing,
        }, className);
        // fix https://fb.me/react-unknown-prop
        const divProps = omit(otherProps, ['onClose', 'afterClose']);
        const tagStyle = {
            ...style,
        };
        if (color && !isPresetColor) {
            tagStyle.backgroundColor = color;
        }
        const tag = closed ? null : (<div hidden={closing} {...divProps} className={classString} style={tagStyle}>
        {children}
        {closeIcon}
      </div>);
        return (<Animate component="" hiddenProp="hidden" transitionName={`${prefixCls}-zoom`} transitionAppear onEnd={this.animationEnd}>
        {tag}
      </Animate>);
    }
}
Tag.displayName = 'Tag';
Tag.CheckableTag = CheckableTag;
Tag.defaultProps = {
    closable: false,
};
