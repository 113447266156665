import React, { Component } from 'react';
import Tooltip from '../tooltip';
import Icon from '../icon';
import Button from '../button';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale-provider/default';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class Popconfirm extends Component {
    constructor(props) {
        super(props);
        this.onConfirm = e => {
            this.setVisible(false);
            const { onConfirm } = this.props;
            if (onConfirm) {
                onConfirm.call(this, e);
            }
        };
        this.onCancel = e => {
            this.setVisible(false);
            const { onCancel } = this.props;
            if (onCancel) {
                onCancel.call(this, e);
            }
        };
        this.onVisibleChange = (visible) => {
            this.setVisible(visible);
        };
        this.saveTooltip = (node) => {
            this.tooltip = node;
        };
        this.renderOverlay = (popconfirmLocale) => {
            const { title, cancelText, okText, okType } = this.props;
            const prefixCls = this.getPrefixCls();
            return (<div>
        <div className={`${prefixCls}-inner-content`}>
          <div className={`${prefixCls}-message`}>
            <Icon type="warning"/>
            <div className={`${prefixCls}-message-title`}>{title}</div>
          </div>
          <div className={`${prefixCls}-buttons`}>
            <Button onClick={this.onCancel} size={Size.small}>
              {cancelText || popconfirmLocale.cancelText}
            </Button>
            <Button onClick={this.onConfirm} type={okType} size={Size.small}>
              {okText || popconfirmLocale.okText}
            </Button>
          </div>
        </div>
      </div>);
        };
        this.state = {
            visible: props.visible,
        };
    }
    componentWillReceiveProps(nextProps) {
        if ('visible' in nextProps) {
            this.setState({ visible: nextProps.visible });
        }
    }
    getPopupDomNode() {
        return this.tooltip.getPopupDomNode();
    }
    setVisible(visible) {
        const props = this.props;
        if (!('visible' in props)) {
            this.setState({ visible });
        }
        const { onVisibleChange } = props;
        if (onVisibleChange) {
            onVisibleChange(visible);
        }
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('popover', prefixCls);
    }
    render() {
        const { placement, ...restProps } = this.props;
        const { visible } = this.state;
        const overlay = (<LocaleReceiver componentName="Popconfirm" defaultLocale={defaultLocale.Popconfirm}>
        {this.renderOverlay}
      </LocaleReceiver>);
        return (<Tooltip {...restProps} prefixCls={this.getPrefixCls()} placement={placement} onVisibleChange={this.onVisibleChange} visible={visible} overlay={overlay} ref={this.saveTooltip}/>);
    }
}
Popconfirm.displayName = 'Popconfirm';
Popconfirm.defaultProps = {
    transitionName: 'zoom-big',
    placement: 'top',
    trigger: 'click',
    okType: 'primary',
};
