import React, { cloneElement, Component } from 'react';
import classNames from 'classnames';
import Input from './Input';
import Button from '../button';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class Search extends Component {
    constructor() {
        super(...arguments);
        this.onSearch = () => {
            const { onSearch } = this.props;
            if (onSearch) {
                onSearch(this.input.input.value);
            }
            this.input.focus();
        };
        this.saveInput = (node) => {
            this.input = node;
        };
    }
    focus() {
        this.input.focus();
    }
    blur() {
        this.input.blur();
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('input-search', prefixCls);
    }
    getButtonOrIcon() {
        const { enterButton, size } = this.props;
        if (!enterButton) {
            return <Button type="primary" size={size} shape="circle" icon="search"/>;
        }
        const enterButtonAsElement = enterButton;
        if (enterButtonAsElement.type === Button || enterButtonAsElement.type === 'button') {
            return cloneElement(enterButtonAsElement, enterButtonAsElement.type === Button
                ? {
                    className: `${this.getPrefixCls()}-button`,
                    size,
                    onClick: this.onSearch,
                }
                : {
                    onClick: this.onSearch,
                });
        }
        if (enterButton === true) {
            return (<Button type="primary" size={size} shape="circle" onClick={this.onSearch} icon="search"/>);
        }
        return (<Button type="primary" size={size} onClick={this.onSearch} key="enterButton">
        {enterButton}
      </Button>);
    }
    render() {
        const { className, inputPrefixCls, size, suffix, enterButton, ...others } = this.props;
        const prefixCls = this.getPrefixCls();
        delete others.onSearch;
        delete others.prefixCls;
        const buttonOrIcon = this.getButtonOrIcon();
        const searchSuffix = suffix ? [suffix, buttonOrIcon] : buttonOrIcon;
        const inputClassName = classNames(prefixCls, className, {
            [`${prefixCls}-enter-button`]: !!enterButton,
            [`${prefixCls}-${size}`]: !!size,
        });
        return (<Input onPressEnter={this.onSearch} {...others} size={size} className={inputClassName} prefixCls={getPrefixCls('input', inputPrefixCls)} suffix={searchSuffix} ref={this.saveInput}/>);
    }
}
Search.displayName = 'Search';
Search.defaultProps = {
    enterButton: false,
    size: Size.small,
};
