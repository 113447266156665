import React, { Component } from 'react';
import classNames from 'classnames';
import { getPrefixCls } from '../configure';
export default class CheckableTag extends Component {
    constructor() {
        super(...arguments);
        this.handleClick = () => {
            const { checked, onChange } = this.props;
            if (onChange) {
                onChange(!checked);
            }
        };
    }
    render() {
        const { prefixCls: customizePrefixCls, className, checked, ...restProps } = this.props;
        const prefixCls = getPrefixCls('tag', customizePrefixCls);
        const cls = classNames(prefixCls, {
            [`${prefixCls}-checkable`]: true,
            [`${prefixCls}-checkable-checked`]: checked,
        }, className);
        delete restProps.onChange; // TypeScript cannot check delete now.
        return <div {...restProps} className={cls} onClick={this.handleClick}/>;
    }
}
CheckableTag.displayName = 'CheckableTag';
