import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { getPrefixCls } from '../configure';
import { matchMediaPolifill } from '../_util/mediaQueryListPolyfill';
// matchMedia polyfill for
// https://github.com/WickyNilliams/enquire.js/issues/82
if (typeof window !== 'undefined') {
    // const matchMediaPolyfill = (mediaQuery: string): MediaQueryList => {
    //   return {
    //     media: mediaQuery,
    //     matches: false,
    //     addListener() {
    //     },
    //     removeListener() {
    //     },
    //   };
    // };
    window.matchMedia = window.matchMedia || matchMediaPolifill;
}
// Use require over import (will be lifted up)
// make sure matchMedia polyfill run before require('react-slick')
const SlickCarousel = require('react-slick').default;
export default class Carousel extends Component {
    constructor() {
        super(...arguments);
        this.onWindowResized = debounce(() => {
            const { autoplay } = this.props;
            if (autoplay && this.slick && this.slick.innerSlider && this.slick.innerSlider.autoPlay) {
                this.slick.innerSlider.autoPlay();
            }
        }, 500, {
            leading: false,
        });
        this.saveSlick = (node) => {
            this.slick = node;
        };
    }
    componentDidMount() {
        const { autoplay } = this.props;
        if (autoplay) {
            window.addEventListener('resize', this.onWindowResized);
        }
        this.innerSlider = this.slick && this.slick.innerSlider;
    }
    componentWillUnmount() {
        const { autoplay } = this.props;
        if (autoplay) {
            window.removeEventListener('resize', this.onWindowResized);
            this.onWindowResized.cancel();
        }
    }
    next() {
        this.slick.slickNext();
    }
    prev() {
        this.slick.slickPrev();
    }
    goTo(slide) {
        this.slick.slickGoTo(slide);
    }
    render() {
        const props = {
            ...this.props,
        };
        if (props.effect === 'fade') {
            props.fade = true;
        }
        const prefixCls = getPrefixCls('carousel', props.prefixCls);
        const className = classNames(prefixCls, {
            [`${prefixCls}-vertical`]: props.vertical,
        });
        return (<div className={className}>
        <SlickCarousel ref={this.saveSlick} {...props}/>
      </div>);
    }
}
Carousel.displayName = 'Carousel';
Carousel.defaultProps = {
    dots: true,
    arrows: false,
    draggable: false,
};
