import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Size } from '../_util/enum';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale-provider/default';
import RcSelect, { OptGroup, Option } from '../rc-components/select';
import { SelectMode } from './enum';
import { getPrefixCls } from '../configure';
// => It is needless to export the declaration of below two inner components.
// export { Option, OptGroup };
export default class Select extends Component {
    constructor() {
        super(...arguments);
        this.saveSelect = (node) => {
            this.rcSelect = node;
        };
        this.renderSelect = (locale) => {
            const { prefixCls: customizePrefixCls, className = '', size, mode, ...restProps } = this.props;
            const prefixCls = getPrefixCls('select', customizePrefixCls);
            const cls = classNames({
                [`${prefixCls}-lg`]: size === Size.large,
                [`${prefixCls}-sm`]: size === Size.small,
            }, className);
            let { optionLabelProp } = this.props;
            const isCombobox = mode === SelectMode.combobox;
            if (isCombobox) {
                // children 带 dom 结构时，无法填入输入框
                optionLabelProp = optionLabelProp || 'value';
            }
            const modeConfig = {
                multiple: mode === SelectMode.multiple,
                tags: mode === SelectMode.tags,
                combobox: isCombobox,
            };
            return (<RcSelect {...restProps} {...modeConfig} prefixCls={prefixCls} className={cls} optionLabelProp={optionLabelProp || 'children'} filterPlaceholder={locale.filterPlaceholder} notFoundContent={this.getNotFoundContent(locale)} ref={this.saveSelect}/>);
        };
    }
    focus() {
        this.rcSelect.focus();
    }
    blur() {
        this.rcSelect.blur();
    }
    getNotFoundContent(locale) {
        const { notFoundContent, mode } = this.props;
        const isCombobox = mode === SelectMode.combobox;
        if (isCombobox) {
            // AutoComplete don't have notFoundContent defaultly
            return notFoundContent === undefined ? null : notFoundContent;
        }
        return notFoundContent === undefined ? locale.notFoundContent : notFoundContent;
    }
    render() {
        return (<LocaleReceiver componentName="Select" defaultLocale={defaultLocale.Select}>
        {this.renderSelect}
      </LocaleReceiver>);
    }
}
Select.displayName = 'Select';
Select.Option = Option;
Select.OptGroup = OptGroup;
Select.defaultProps = {
    blurChange: true,
    showSearch: false,
    transitionName: 'slide-up',
    choiceTransitionName: 'zoom',
    filter: false,
    showCheckAll: true,
    choiceRemove: true,
    border: true,
};
Select.propTypes = {
    prefixCls: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([Size.default, Size.large, Size.small]),
    combobox: PropTypes.bool,
    notFoundContent: PropTypes.any,
    showSearch: PropTypes.bool,
    optionLabelProp: PropTypes.string,
    transitionName: PropTypes.string,
    choiceTransitionName: PropTypes.string,
    showNotFindInputItem: PropTypes.bool,
    showNotFindSelectedItem: PropTypes.bool,
    filter: PropTypes.bool,
    showCheckAll: PropTypes.bool,
    footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    choiceRender: PropTypes.func,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    filterValue: PropTypes.string,
    onFilterChange: PropTypes.func,
    choiceRemove: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    onChoiceRemove: PropTypes.func,
    onClear: PropTypes.func,
    border: PropTypes.bool,
    blurChange: PropTypes.bool,
};
