import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import RcSwitch from '../rc-components/switch';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class Switch extends Component {
    constructor() {
        super(...arguments);
        this.saveSwitch = (node) => {
            this.rcSwitch = node;
        };
    }
    focus() {
        this.rcSwitch.focus();
    }
    blur() {
        this.rcSwitch.blur();
    }
    render() {
        const { prefixCls: customizePrefixCls, size, loading, className = '' } = this.props;
        const prefixCls = getPrefixCls('switch', customizePrefixCls);
        const classes = classNames(className, {
            [`${prefixCls}-small`]: size === Size.small,
            [`${prefixCls}-loading`]: loading,
        });
        return (<RcSwitch {...omit(this.props, ['loading'])} prefixCls={prefixCls} className={classes} ref={this.saveSwitch}/>);
    }
}
Switch.displayName = 'Switch';
Switch.propTypes = {
    prefixCls: PropTypes.string,
    // size=default and size=large are the same
    size: PropTypes.oneOf([Size.small, Size.default, Size.large]),
    className: PropTypes.string,
};
