import React from 'react';
import noop from 'lodash/noop';
import isString from 'lodash/isString';
import Icon from '../icon';
import Notification from '../rc-components/notification';
import { getPlacementStyle, getPlacementTransitionName } from './util';
import { getPrefixCls } from '../configure';
let defaultDuration = 3;
let defaultTop = 24;
let defaultBottom = 24;
let messageInstance;
let key = 1;
let customizePrefixCls;
let transitionName = 'move-up';
let defaultPlacement = 'leftBottom';
let getContainer;
function getCustomizePrefixCls() {
    return getPrefixCls('message', customizePrefixCls);
}
function getMessageInstance(placement, callback) {
    if (messageInstance) {
        callback(messageInstance);
        return;
    }
    Notification.newInstance({
        prefixCls: getCustomizePrefixCls(),
        style: getPlacementStyle(placement, defaultTop, defaultBottom),
        transitionName: getPlacementTransitionName(placement, transitionName),
        getContainer,
    }, (instance) => {
        if (messageInstance) {
            callback(messageInstance);
            return;
        }
        messageInstance = instance;
        callback(instance);
    });
}
function notice(content, duration = defaultDuration, type, onClose, placement) {
    const iconType = {
        info: 'info',
        success: 'check_circle',
        error: 'error',
        warning: 'warning',
        loading: 'loading',
    }[type];
    if (isString(onClose)) {
        placement = onClose;
        onClose = noop;
    }
    if (typeof duration === 'function') {
        onClose = duration;
        duration = defaultDuration;
    }
    else if (isString(duration)) {
        placement = duration;
    }
    const target = key++;
    const prefixCls = getCustomizePrefixCls();
    getMessageInstance(placement || defaultPlacement, instance => {
        instance.notice({
            key: target,
            duration,
            style: {},
            content: (<div className={`${prefixCls}-custom-content ${prefixCls}-${type}`}>
          <Icon type={iconType}/>
          <span>{content}</span>
        </div>),
            onClose,
        });
    });
    return () => {
        if (messageInstance) {
            messageInstance.removeNotice(target);
        }
    };
}
export default {
    info(content, duration, onClose, placement) {
        return notice(content, duration, 'info', onClose, placement);
    },
    success(content, duration, onClose, placement) {
        return notice(content, duration, 'success', onClose, placement);
    },
    error(content, duration, onClose, placement) {
        return notice(content, duration, 'error', onClose, placement);
    },
    // Departed usage, please use warning()
    warn(content, duration, onClose, placement) {
        return notice(content, duration, 'warning', onClose, placement);
    },
    warning(content, duration, onClose, placement) {
        return notice(content, duration, 'warning', onClose, placement);
    },
    loading(content, duration, onClose, placement) {
        return notice(content, duration, 'loading', onClose, placement);
    },
    config(options) {
        if (options.top !== undefined) {
            defaultTop = options.top;
            messageInstance = null; // delete messageInstance for new defaultTop
        }
        if (options.bottom !== undefined) {
            defaultBottom = options.bottom;
            messageInstance = null; // delete messageInstance for new defaultBottom
        }
        if (options.duration !== undefined) {
            defaultDuration = options.duration;
        }
        if (options.prefixCls !== undefined) {
            customizePrefixCls = options.prefixCls;
        }
        if (options.getContainer !== undefined) {
            getContainer = options.getContainer;
        }
        if (options.transitionName !== undefined) {
            transitionName = options.transitionName;
            messageInstance = null; // delete messageInstance for new transitionName
        }
        if (options.placement !== undefined) {
            defaultPlacement = options.placement;
        }
    },
    destroy() {
        if (messageInstance) {
            messageInstance.destroy();
            messageInstance = null;
        }
    },
};
