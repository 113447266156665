import React, { Component } from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import Tooltip from '../tooltip';
import Progress from '../progress';
import Animate from '../animate';
import { ProgressType } from '../progress/enum';
import { getPrefixCls } from '../configure';
// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(file);
};
const isImageUrl = (url) => {
    return /^data:image\//.test(url) || /\.(webp|svg|png|gif|jpg|jpeg)$/.test(url);
};
export default class UploadList extends Component {
    constructor() {
        super(...arguments);
        this.handleClose = (file) => {
            const { onRemove } = this.props;
            if (onRemove) {
                onRemove(file);
            }
        };
        this.handlePreview = (file, e) => {
            const { onPreview } = this.props;
            if (!onPreview) {
                return;
            }
            e.preventDefault();
            return onPreview(file);
        };
    }
    componentDidUpdate() {
        const { listType, items } = this.props;
        if (listType !== 'picture' && listType !== 'picture-card') {
            return;
        }
        (items || []).forEach(file => {
            if (typeof document === 'undefined' ||
                typeof window === 'undefined' ||
                !window.FileReader ||
                !window.File ||
                !(file.originFileObj instanceof File) ||
                file.thumbUrl !== undefined) {
                return;
            }
            /* eslint-disable-next-line */
            file.thumbUrl = '';
            previewFile(file.originFileObj, (previewDataUrl) => {
                /* eslint-disable-next-line */
                file.thumbUrl = previewDataUrl;
                this.forceUpdate();
            });
        });
    }
    render() {
        const { prefixCls: customizePrefixCls, items = [], listType, showPreviewIcon, showRemoveIcon, locale, } = this.props;
        const prefixCls = getPrefixCls('upload', customizePrefixCls);
        const list = items.map(file => {
            let progress;
            let icon = <Icon type={file.status === 'uploading' ? 'loading' : 'attach_file'}/>;
            if (listType === 'picture' || listType === 'picture-card') {
                if (listType === 'picture-card' && file.status === 'uploading') {
                    icon = <div className={`${prefixCls}-list-item-uploading-text`}>{locale.uploading}</div>;
                }
                else if (!file.thumbUrl && !file.url) {
                    icon = <Icon className={`${prefixCls}-list-item-thumbnail`} type="picture"/>;
                }
                else {
                    const thumbnail = isImageUrl((file.thumbUrl || file.url)) ? (<img src={file.thumbUrl || file.url} alt={file.name}/>) : (<Icon type="file" style={{ fontSize: 48, color: 'rgba(0,0,0,0.5)' }}/>);
                    icon = (<a className={`${prefixCls}-list-item-thumbnail`} onClick={e => this.handlePreview(file, e)} href={file.url || file.thumbUrl} target="_blank" rel="noopener noreferrer">
              {thumbnail}
            </a>);
                }
            }
            if (file.status === 'uploading') {
                const { progressAttr } = this.props;
                // show loading icon if upload progress listener is disabled
                const loadingProgress = 'percent' in file ? (<Progress type={ProgressType.line} {...progressAttr} percent={file.percent}/>) : null;
                progress = (<div className={`${prefixCls}-list-item-progress`} key="progress">
            {loadingProgress}
          </div>);
            }
            const infoUploadingClass = classNames({
                [`${prefixCls}-list-item`]: true,
                [`${prefixCls}-list-item-${file.status}`]: true,
            });
            const preview = file.url ? (<a {...file.linkProps} href={file.url} target="_blank" rel="noopener noreferrer" className={`${prefixCls}-list-item-name`} onClick={e => this.handlePreview(file, e)} title={file.name}>
          {file.name}
        </a>) : (<span className={`${prefixCls}-list-item-name`} onClick={e => this.handlePreview(file, e)} title={file.name}>
          {file.name}
        </span>);
            const style = file.url || file.thumbUrl
                ? undefined
                : {
                    pointerEvents: 'none',
                    opacity: 0.5,
                };
            const previewIcon = showPreviewIcon ? (<a href={file.url || file.thumbUrl} target="_blank" rel="noopener noreferrer" style={style} onClick={e => this.handlePreview(file, e)} title={locale.previewFile}>
          <Icon type="visibility"/>
        </a>) : null;
            const removeIcon = showRemoveIcon ? (<Icon type="delete" title={locale.removeFile} onClick={() => this.handleClose(file)}/>) : null;
            const removeIconCross = showRemoveIcon ? (<Icon type="close" title={locale.removeFile} onClick={() => this.handleClose(file)}/>) : null;
            const actions = listType === 'picture-card' && file.status !== 'uploading' ? (<span className={`${prefixCls}-list-item-actions`}>
            {previewIcon}
            {removeIcon}
          </span>) : (removeIconCross);
            let message;
            if (file.response && typeof file.response === 'string') {
                message = file.response;
            }
            else {
                message = (file.error && file.error.statusText) || locale.uploadError;
            }
            const iconAndPreview = file.status === 'error' ? (<Tooltip title={message}>
            {icon}
            {preview}
          </Tooltip>) : (<span>
            {icon}
            {preview}
          </span>);
            return (<div className={infoUploadingClass} key={file.uid}>
          <div className={`${prefixCls}-list-item-info`}>{iconAndPreview}</div>
          {actions}
          <Animate transitionName="fade" component="">
            {progress}
          </Animate>
        </div>);
        });
        const listClassNames = classNames({
            [`${prefixCls}-list`]: true,
            [`${prefixCls}-list-${listType}`]: true,
        });
        const animationDirection = listType === 'picture-card' ? 'animate-inline' : 'animate';
        return (<Animate transitionName={`${prefixCls}-${animationDirection}`} component="div" className={listClassNames}>
        {list}
      </Animate>);
    }
}
UploadList.displayName = 'UploadList';
UploadList.defaultProps = {
    listType: 'text',
    progressAttr: {
        strokeWidth: 2,
        showInfo: false,
    },
    showRemoveIcon: true,
    showPreviewIcon: true,
};
