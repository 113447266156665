import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SubMenu as RcSubMenu } from '../rc-components/menu';
class SubMenu extends Component {
    constructor() {
        super(...arguments);
        this.onKeyDown = e => {
            this.subMenu.onKeyDown(e);
        };
        this.saveSubMenu = (subMenu) => {
            this.subMenu = subMenu;
        };
    }
    render() {
        const { rootPrefixCls, className } = this.props;
        const { menuTheme } = this.context;
        return (<RcSubMenu {...this.props} ref={this.saveSubMenu} popupClassName={classNames(`${rootPrefixCls}-${menuTheme}`, className)}/>);
    }
}
SubMenu.contextTypes = {
    menuTheme: PropTypes.string,
};
export default SubMenu;
