import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Icon from '../icon';
import Select from '../select';
import { filterByInputValue, getColumnKey } from './util';
import Checkbox from '../checkbox/Checkbox';
import { SelectMode } from '../select/enum';
import { getPrefixCls } from '../configure';
const { Option, OptGroup } = Select;
const PAIR_SPLIT = ':';
const VALUE_SPLIT = '、';
const OPTION_OR = 'option-or';
export const VALUE_OR = 'OR';
function pairValue(column, value = '') {
    const { filters } = column;
    const found = filters && filters.find(filter => String(filter.value) === value);
    return {
        key: `${getColumnKey(column)}${PAIR_SPLIT}${value}`,
        label: [column.filterTitle || column.title, PAIR_SPLIT, ' ', found ? found.text : value],
    };
}
function barPair(value, index) {
    return {
        key: `${value}${PAIR_SPLIT}${index}`,
        label: [value],
    };
}
function removeDoubleOr(filters) {
    return filters.filter(({ label }, index) => label !== VALUE_OR || label !== filters[index + 1]);
}
export default class FilterSelect extends Component {
    constructor(props) {
        super(props);
        this.columnRefs = {};
        this.handleDropdownMouseDown = e => {
            e.preventDefault();
            this.rcSelect.focus();
        };
        this.saveRef = (node) => {
            if (node) {
                this.rcSelect = node.rcSelect;
            }
        };
        this.saveColumnRef = (key, node) => {
            if (node) {
                this.columnRefs[key] = node;
            }
        };
        this.handleInputKeyDown = (e) => {
            const { value } = e.target;
            if (e.keyCode === 13 && !e.isDefaultPrevented() && value) {
                const { filters, columnFilters, selectColumn } = this.state;
                if (selectColumn) {
                    const key = getColumnKey(selectColumn);
                    if (key) {
                        const { filters: columFilters } = selectColumn;
                        const filterText = value.split(this.getColumnTitle(selectColumn)).slice(1);
                        columnFilters[key] = filterText;
                        const found = columFilters && columFilters.find(filter => filter.text === filterText[0]);
                        const filterValue = found ? String(found.value) : filterText[0];
                        this.fireColumnFilterChange(key, [filterValue]);
                    }
                }
                else {
                    filters.push(value);
                    this.fireChange(filters);
                }
                this.setState({
                    inputValue: '',
                    filters,
                    columnFilters,
                    selectColumn: undefined,
                });
                this.rcSelect.setState({
                    inputValue: '',
                });
            }
        };
        this.handleInput = (value) => {
            let { selectColumn } = this.state;
            if (selectColumn) {
                if (value.indexOf(this.getColumnTitle(selectColumn)) === -1) {
                    selectColumn = undefined;
                }
            }
            this.setState({
                selectColumn,
                inputValue: value,
            });
        };
        this.handleChoiceItemClick = ({ key }) => {
            const pair = key.split(PAIR_SPLIT);
            if (pair.length > 1) {
                const columnKey = pair.shift();
                const selectColumn = this.findColumn(columnKey);
                if (selectColumn && selectColumn.filterMultiple) {
                    const { filters } = selectColumn;
                    const checked = pair
                        .join(PAIR_SPLIT)
                        .split(VALUE_SPLIT)
                        .map(text => {
                        const found = filters && filters.find(filter => filter.text === text);
                        return found ? found.value : text;
                    });
                    this.setState({
                        selectColumn,
                        checked,
                    });
                }
            }
        };
        this.handleSelect = ({ key }) => {
            const { checked, selectColumn } = this.state;
            if (key === '__ok__') {
                this.handleMultiCheckConfirm();
            }
            else if (key !== `${selectColumn && selectColumn.title}:`) {
                const index = checked.indexOf(key);
                if (index === -1) {
                    checked.push(key);
                }
                else {
                    checked.splice(index, 1);
                }
                this.setState({
                    checked,
                }, () => {
                    if (selectColumn) {
                        const { columnFilters } = this.state;
                        const columnKey = getColumnKey(selectColumn);
                        if (columnKey) {
                            const filters = columnFilters[columnKey];
                            if (!filters || !filters.length) {
                                this.rcSelect.setState({
                                    inputValue: this.getColumnTitle(selectColumn),
                                });
                            }
                        }
                    }
                });
            }
            return false;
        };
        this.handleMultiCheckConfirm = () => {
            const { selectColumn, checked } = this.state;
            if (selectColumn) {
                const columnKey = getColumnKey(selectColumn);
                if (columnKey) {
                    this.fireColumnFilterChange(columnKey, checked);
                    this.setState({
                        selectColumn: undefined,
                        checked: [],
                    });
                    this.rcSelect.setState({
                        inputValue: '',
                    });
                }
            }
        };
        this.handleClear = () => {
            this.setState({ selectColumn: undefined });
        };
        this.handleChange = (changedValue) => {
            const { state, rcSelect } = this;
            const { selectColumn, inputValue, columnFilters } = state;
            let { filters } = state;
            const all = this.getValue();
            let change = false;
            if (changedValue.length > all.length) {
                const value = changedValue.pop();
                if (inputValue) {
                    if (rcSelect.state.inputValue && value) {
                        change = true;
                        filters.push(value.label);
                    }
                    this.setState({
                        selectColumn: undefined,
                        inputValue: '',
                        filters,
                    });
                }
                else if (value && value.label === OPTION_OR) {
                    filters.push(VALUE_OR);
                    change = true;
                    this.setState({
                        filters,
                    });
                }
                else if (selectColumn) {
                    if (!selectColumn.filterMultiple) {
                        const columnKey = getColumnKey(selectColumn);
                        if (rcSelect.state.inputValue && value && columnKey) {
                            this.fireColumnFilterChange(columnKey, [value.key]);
                        }
                        this.setState({
                            selectColumn: undefined,
                        });
                    }
                    else {
                        this.setState({
                            selectColumn: undefined,
                            checked: [],
                        });
                        rcSelect.setState({
                            inputValue: '',
                        });
                    }
                }
                else if (value) {
                    const column = this.findColumn(value.key);
                    const columnFilter = columnFilters[value.key];
                    if (column && (!columnFilter || !columnFilter.length)) {
                        rcSelect.setState({
                            inputValue: this.getColumnTitle(column),
                        });
                    }
                    this.setState({
                        selectColumn: column,
                    });
                }
            }
            else {
                filters = this.changeValue(changedValue, rcSelect.state.value);
                if (state.filters.length !== filters.length) {
                    change = true;
                }
                this.setState({
                    inputValue: '',
                    filters,
                });
            }
            if (change) {
                this.fireChange(filters);
            }
        };
        this.toValueString = (item) => {
            const key = Object.keys(item)[0];
            const col = this.findColumn(key);
            if (col) {
                return pairValue(col, item[key]);
            }
            return '';
        };
        this.getRootDomNode = () => {
            return findDOMNode(this).querySelector(`.${getPrefixCls('select')}-search__field`);
        };
        this.state = {
            columns: this.getColumnsWidthFilters(),
            filters: props.filters || [],
            columnFilters: props.columnFilters || {},
            inputValue: '',
            selectColumn: undefined,
            checked: [],
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            columns: this.getColumnsWidthFilters(nextProps),
        });
        if (nextProps.filters) {
            this.setState({
                filters: nextProps.filters,
            });
        }
        if (nextProps.columnFilters) {
            this.setState({
                columnFilters: nextProps.columnFilters,
            });
        }
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return `${prefixCls}-filter-select`;
    }
    render() {
        const { placeholder, getPopupContainer } = this.props;
        const { inputValue } = this.state;
        const prefixCls = this.getPrefixCls();
        const multiple = this.isMultiple();
        return (<div className={prefixCls}>
        <div className={`${prefixCls}-icon`}>
          <Icon type="filter_list"/>
        </div>
        <Select ref={this.saveRef} mode={SelectMode.tags} filterOption={false} onChange={this.handleChange} onSelect={multiple ? this.handleSelect : undefined} onInput={this.handleInput} onInputKeyDown={this.handleInputKeyDown} onClear={this.handleClear} value={this.getValue()} placeholder={placeholder} notFoundContent={false} showNotFindInputItem={false} showNotFindSelectedItem={false} dropdownMatchSelectWidth={false} defaultActiveFirstOption={!inputValue} dropdownStyle={{ minWidth: 256 }} onDropdownMouseDown={this.handleDropdownMouseDown} dropdownClassName={`${prefixCls}-dropdown`} getRootDomNode={this.getRootDomNode} showCheckAll={false} onChoiceItemClick={this.handleChoiceItemClick} getPopupContainer={getPopupContainer} allowClear labelInValue blurChange={false} border={false}>
          {this.getOptions()}
        </Select>
        <div className={`${prefixCls}-columns`}>{this.renderColumnsTitle()}</div>
      </div>);
    }
    renderColumnsTitle() {
        const { columns } = this.state;
        this.columnRefs = {};
        return columns.map(col => {
            const key = getColumnKey(col);
            return (<span ref={this.saveColumnRef.bind(this, key)} key={key}>
          {col.filterTitle || col.title}
        </span>);
        });
    }
    isMultiple() {
        const { selectColumn } = this.state;
        if (selectColumn) {
            return selectColumn.filterMultiple;
        }
        return false;
    }
    fireChange(filters) {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(filters);
        }
    }
    fireColumnFilterChange(columnKey, value) {
        const col = this.findColumn(columnKey);
        const { onFilter } = this.props;
        if (col && onFilter) {
            onFilter(col, value || null);
        }
    }
    changeValue(changedValue, oldValue) {
        const { state } = this;
        const changedColumnKeys = [];
        const changedColumnFilters = state.columnFilters;
        const columnFiltersValues = this.getColumnFiltersValues();
        if (changedValue.length) {
            const len = columnFiltersValues.length;
            if (len > 0) {
                const index = oldValue.findIndex((item, i) => item !== (changedValue[i] && changedValue[i].key));
                if (index < columnFiltersValues.length) {
                    const deleted = changedValue.splice(0, len - 1);
                    if (deleted.length < 2 && changedValue[0] && changedValue[0].label === VALUE_OR) {
                        changedValue.shift();
                    }
                    let value = columnFiltersValues[index];
                    if (value === VALUE_OR) {
                        value = columnFiltersValues[index + 1];
                    }
                    const columnKey = Object.keys(value)[0];
                    const columnFilters = changedColumnFilters[columnKey].slice();
                    const column = this.findColumn(columnKey);
                    if (column) {
                        const { filters } = column;
                        value[columnKey].split(VALUE_SPLIT).forEach((text) => {
                            const found = filters && filters.find(filter => filter.text === text);
                            const filterIndex = columnFilters.indexOf(found ? found.value : text);
                            if (filterIndex !== -1) {
                                columnFilters.splice(filterIndex, 1);
                                changedColumnFilters[columnKey] = columnFilters;
                                if (changedColumnKeys.indexOf(columnKey) === -1) {
                                    changedColumnKeys.push(columnKey);
                                }
                            }
                        });
                    }
                }
                else {
                    changedValue.splice(0, len);
                }
            }
            changedColumnKeys.forEach(key => {
                this.fireColumnFilterChange(key, changedColumnFilters[key]);
            });
        }
        else {
            const { onClear } = this.props;
            if (onClear) {
                onClear();
            }
        }
        return removeDoubleOr(changedValue).map(item => {
            const label = item.label;
            if (label.constructor === Array) {
                return label && label[0];
            }
            return label;
        });
    }
    getColumnFiltersValues() {
        const values = [];
        const { columnFilters } = this.state;
        Object.keys(columnFilters).forEach(c => {
            const filteredValue = columnFilters[c];
            const column = this.findColumn(c);
            if (filteredValue && filteredValue.length && column) {
                const { filters } = column;
                values.push({
                    [c]: filteredValue
                        .map(value => {
                        const found = filters && filters.find(filter => String(filter.value) === String(value));
                        return found ? found.text : value;
                    })
                        .join(VALUE_SPLIT),
                });
            }
        });
        return values;
    }
    getValue() {
        const { filters } = this.state;
        return this.getColumnFiltersValues()
            .map(this.toValueString)
            .concat(filters.map(barPair));
    }
    getInputFilterOptions(inputValue) {
        const { columns, dataSource } = this.props;
        const options = [];
        if (dataSource && columns) {
            const values = {};
            filterByInputValue(dataSource, columns, inputValue, (record, column) => {
                const { dataIndex } = column;
                if (dataIndex) {
                    const value = record[dataIndex].toString();
                    if (!values[value]) {
                        values[value] = true;
                        options.push(<Option key={value} value={value}>
                  {value}
                </Option>);
                    }
                }
            });
        }
        return options;
    }
    getOptions() {
        const { state } = this;
        const { selectColumn, inputValue, columns, checked, columnFilters } = state;
        if (selectColumn) {
            if (inputValue && inputValue.split(PAIR_SPLIT)[1]) {
                return null;
            }
            const { filters, filterMultiple } = selectColumn;
            const columnKey = getColumnKey(selectColumn);
            if (filters) {
                return filters
                    .filter(filter => !filter.children)
                    .map((filter, i) => {
                    const value = String(filter.value);
                    let text = filter.text;
                    if (filterMultiple && columnKey) {
                        let _checked = columnFilters[columnKey];
                        if (_checked && !checked.length) {
                            state.checked = _checked.slice();
                        }
                        else {
                            _checked = checked;
                        }
                        text = [
                            <Checkbox key="ck" className="multiple" checked={_checked.indexOf(value) !== -1}/>,
                            text,
                        ];
                    }
                    return (<Option key={`filter-${String(i)}`} value={value}>
                {text}
              </Option>);
                })
                    .concat(filterMultiple ? (<OptGroup key="ok">
                <Option value="__ok__" className={`${this.getPrefixCls()}-ok-btn`}>
                  确认
                </Option>
              </OptGroup>) : ([]));
            }
        }
        else if (inputValue) {
            return this.getInputFilterOptions(inputValue);
        }
        else {
            const { filters } = this.state;
            const { multiple } = this.props;
            const { length } = filters;
            const value = this.getColumnFiltersValues();
            const keys = value.map(item => Object.keys(item)[0]);
            const options = columns.reduce((opts, column, i) => {
                const key = getColumnKey(column, i);
                if (keys.indexOf(key) === -1 || column.filterMultiple) {
                    opts.push(<Option key={`column-${key}`} value={key}>
              <span>{column.filterTitle || column.title}</span>
            </Option>);
                }
                return opts;
            }, []);
            if (multiple && (length ? filters[length - 1] !== VALUE_OR : value.length)) {
                return [
                    <OptGroup key="or">
            <Option value={OPTION_OR}>OR</Option>
          </OptGroup>,
                    <OptGroup key="all">{options}</OptGroup>,
                ];
            }
            return options;
        }
    }
    getColumnsWidthFilters(props = this.props) {
        return (props.columns || []).filter(column => column.filters instanceof Array);
    }
    findColumn(myKey) {
        const { columns } = this.state;
        return columns.find(c => getColumnKey(c) === myKey);
    }
    getColumnTitle(column) {
        const columnKey = getColumnKey(column);
        if (columnKey) {
            return `${this.columnRefs[columnKey].textContent}${PAIR_SPLIT}`;
        }
        return '';
    }
}
