import React, { Component } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from './locale/en_US';
import interopDefault from '../_util/interopDefault';
import RcTimePicker from '../rc-components/time-picker';
import { getPrefixCls } from '../configure';
export function generateShowHourMinuteSecond(format) {
    // Ref: http://momentjs.com/docs/#/parsing/string-format/
    return {
        showHour: format.indexOf('H') > -1 || format.indexOf('h') > -1 || format.indexOf('k') > -1,
        showMinute: format.indexOf('m') > -1,
        showSecond: format.indexOf('s') > -1,
    };
}
export default class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.handleChange = (value) => {
            if (!('value' in this.props)) {
                this.setState({ value });
            }
            const { onChange, format = 'HH:mm:ss' } = this.props;
            if (onChange) {
                onChange(value, (value && value.format(format)) || '');
            }
        };
        this.handleOpenClose = ({ open }) => {
            const { onOpenChange } = this.props;
            if (onOpenChange) {
                onOpenChange(open);
            }
        };
        this.saveTimePicker = (timePickerRef) => {
            this.timePickerRef = timePickerRef;
        };
        this.renderTimePicker = (locale) => {
            const props = {
                ...this.props,
            };
            const { value } = this.state;
            delete props.defaultValue;
            const prefixCls = getPrefixCls('time-picker', props.prefixCls);
            const format = this.getDefaultFormat();
            const className = classNames(props.className, {
                [`${prefixCls}-${props.size}`]: !!props.size,
            });
            const addon = (panel) => props.addon ? <div className={`${prefixCls}-panel-addon`}>{props.addon(panel)}</div> : null;
            return (<RcTimePicker {...generateShowHourMinuteSecond(format)} {...props} prefixCls={prefixCls} ref={this.saveTimePicker} format={format} className={className} value={value} placeholder={props.placeholder === undefined ? locale.placeholder : props.placeholder} onChange={this.handleChange} onOpen={this.handleOpenClose} onClose={this.handleOpenClose} addon={addon}/>);
        };
        const value = props.value || props.defaultValue;
        if (value && !interopDefault(moment).isMoment(value)) {
            throw new Error('The value/defaultValue of TimePicker must be a moment object');
        }
        this.state = {
            value,
        };
    }
    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({ value: nextProps.value });
        }
    }
    focus() {
        this.timePickerRef.focus();
    }
    blur() {
        this.timePickerRef.blur();
    }
    getDefaultFormat() {
        const { format, use12Hours } = this.props;
        if (format) {
            return format;
        }
        if (use12Hours) {
            return 'h:mm:ss a';
        }
        return 'HH:mm:ss';
    }
    render() {
        return (<LocaleReceiver componentName="TimePicker" defaultLocale={defaultLocale}>
        {this.renderTimePicker}
      </LocaleReceiver>);
    }
}
TimePicker.displayName = 'TimePicker';
TimePicker.defaultProps = {
    align: {
        offset: [0, -2],
    },
    disabled: false,
    disabledHours: undefined,
    disabledMinutes: undefined,
    disabledSeconds: undefined,
    hideDisabledOptions: false,
    placement: 'bottomLeft',
    transitionName: 'slide-up',
    focusOnOpen: true,
};
