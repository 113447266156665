import { cloneElement, Component } from 'react';
import { findDOMNode } from 'react-dom';
export default class InputElement extends Component {
    constructor() {
        super(...arguments);
        this.focus = () => {
            const { ele } = this;
            if (typeof ele.focus === 'function') {
                ele.focus();
            }
            else {
                findDOMNode(ele).focus();
            }
        };
        this.blur = () => {
            const { ele } = this;
            if (typeof ele.blur === 'function') {
                ele.blur();
            }
            else {
                findDOMNode(ele).blur();
            }
        };
        this.saveRef = (ele) => {
            this.ele = ele;
            const { children: { ref }, } = this.props;
            if (typeof ref === 'function') {
                ref(ele);
            }
        };
    }
    render() {
        const { props, props: { children }, } = this;
        return cloneElement(children, {
            ...props,
            ref: this.saveRef,
        }, null);
    }
}
