import React, { Children, PureComponent } from 'react';
import RippleChild from './RippleChild';
import { getConfig, getPrefixCls } from '../configure';
export default class Ripple extends PureComponent {
    constructor() {
        super(...arguments);
        this.rippleChild = (child) => {
            const { prefixCls } = this.props;
            return <RippleChild prefixCls={getPrefixCls('ripple', prefixCls)}>{child}</RippleChild>;
        };
    }
    render() {
        const { disabled, children } = this.props;
        if (disabled || !children || !getConfig('ripple')) {
            return children;
        }
        return Children.map(children, this.rippleChild);
    }
}
Ripple.displayName = 'Ripple';
