import React from 'react';
import noop from 'lodash/noop';
import Button from '../button';
import { Size } from '../_util/enum';
const Operation = props => {
    const { moveToLeft = noop, moveToRight = noop, leftArrowText = '', rightArrowText = '', leftActive, rightActive, className, } = props;
    return (<div className={className}>
      <Button type="primary" size={Size.small} disabled={!leftActive} onClick={moveToLeft} icon="left">
        {leftArrowText}
      </Button>
      <Button type="primary" size={Size.small} disabled={!rightActive} onClick={moveToRight} icon="right">
        {rightArrowText}
      </Button>
    </div>);
};
export default Operation;
