import React, { Component } from 'react';
import classNames from 'classnames';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import warning from '../_util/warning';
import RcTreeSelect, { SHOW_ALL, SHOW_CHILD, SHOW_PARENT, TreeNode, } from '../rc-components/tree-select';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export { TreeData, TreeSelectProps } from './interface';
export default class TreeSelect extends Component {
    constructor(props) {
        super(props);
        this.saveTreeSelect = (node) => {
            this.rcTreeSelect = node;
        };
        this.renderTreeSelect = (locale) => {
            const { prefixCls: customizePrefixCls, className, size, notFoundContent, dropdownStyle, dropdownClassName, ...restProps } = this.props;
            const prefixCls = getPrefixCls('select', customizePrefixCls);
            const cls = classNames({
                [`${prefixCls}-lg`]: size === Size.large,
                [`${prefixCls}-sm`]: size === Size.small,
            }, className);
            let checkable = restProps.treeCheckable;
            if (checkable) {
                checkable = <span className={`${prefixCls}-tree-checkbox-inner`}/>;
            }
            return (<RcTreeSelect {...restProps} dropdownClassName={classNames(dropdownClassName, `${prefixCls}-tree-dropdown`)} prefixCls={prefixCls} className={cls} dropdownStyle={{ maxHeight: '100vh', overflow: 'auto', ...dropdownStyle }} treeCheckable={checkable} notFoundContent={notFoundContent || locale.notFoundContent} ref={this.saveTreeSelect}/>);
        };
        warning(props.multiple !== false || !props.treeCheckable, '`multiple` will alway be `true` when `treeCheckable` is true');
    }
    focus() {
        this.rcTreeSelect.focus();
    }
    blur() {
        this.rcTreeSelect.blur();
    }
    render() {
        return (<LocaleReceiver componentName="Select" defaultLocale={{}}>
        {this.renderTreeSelect}
      </LocaleReceiver>);
    }
}
TreeSelect.displayName = 'TreeSelect';
TreeSelect.TreeNode = TreeNode;
TreeSelect.SHOW_ALL = SHOW_ALL;
TreeSelect.SHOW_PARENT = SHOW_PARENT;
TreeSelect.SHOW_CHILD = SHOW_CHILD;
TreeSelect.defaultProps = {
    transitionName: 'slide-up',
    choiceTransitionName: 'zoom',
    showSearch: false,
};
