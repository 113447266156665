(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("mobx"), require("react-dom"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "mobx", "react-dom", "moment"], factory);
	else if(typeof exports === 'object')
		exports["choerodon-ui.min"] = factory(require("react"), require("mobx"), require("react-dom"), require("moment"));
	else
		root["choerodon-ui.min"] = factory(root["React"], root["mobx"], root["ReactDOM"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 