import { Children, isValidElement } from 'react';
export function flatArray(data = [], childrenName = 'children') {
    const result = [];
    const loop = (array) => {
        array.forEach(item => {
            if (item[childrenName]) {
                const newItem = { ...item };
                delete newItem[childrenName];
                result.push(newItem);
                if (item[childrenName].length > 0) {
                    loop(item[childrenName]);
                }
            }
            else {
                result.push(item);
            }
        });
    };
    loop(data);
    return result;
}
export function treeMap(tree, mapper, childrenName = 'children') {
    return tree.map((node, index) => {
        const extra = {};
        if (node[childrenName]) {
            extra[childrenName] = treeMap(node[childrenName], mapper, childrenName);
        }
        return {
            ...mapper(node, index),
            ...extra,
        };
    });
}
export function flatFilter(tree, callback) {
    return tree.reduce((acc, node) => {
        if (callback(node)) {
            acc.push(node);
        }
        if (node.children) {
            const children = flatFilter(node.children, callback);
            acc.push(...children);
        }
        return acc;
    }, []);
}
export function normalizeColumns(elements) {
    const columns = [];
    Children.forEach(elements, element => {
        if (!isValidElement(element)) {
            return;
        }
        const column = {
            ...element.props,
        };
        if (element.key) {
            column.key = element.key;
        }
        if (element.type && element.type.__ANT_TABLE_COLUMN_GROUP) {
            column.children = normalizeColumns(column.children);
        }
        columns.push(column);
    });
    return columns;
}
export function getLeafColumns(columns) {
    return flatFilter(columns, c => !c.children);
}
export function getColumnKey(column, index) {
    return column.key || column.dataIndex || index;
}
export function findColumnByFilterValue(record, columns, inputValue) {
    return columns.find(col => {
        const key = getColumnKey(col);
        if (key) {
            let value = record[key];
            if (value && typeof value !== 'object') {
                value = value.toString();
                if (value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
                    return true;
                }
            }
        }
        return false;
    });
}
export function filterByInputValue(dataSource, columns, inputValue, cb) {
    dataSource.forEach(data => {
        const column = findColumnByFilterValue(data, columns, inputValue);
        if (column) {
            cb(data, column);
        }
    });
}
export function removeHiddenColumns(columns) {
    return columns.filter(c => {
        if (c.hidden) {
            return false;
        }
        if (c.children) {
            const children = removeHiddenColumns(c.children);
            if (children.length) {
                c.children = children;
            }
            else {
                return false;
            }
        }
        return true;
    });
}
