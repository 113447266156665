import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shallowEqual from 'lodash/isEqual';
import RcCheckbox from '../rc-components/checkbox';
import { getPrefixCls } from '../configure';
export default class Radio extends Component {
    constructor() {
        super(...arguments);
        this.saveCheckbox = (node) => {
            this.rcCheckbox = node;
        };
        this.onChange = (e) => {
            const { onChange } = this.props;
            const { radioGroup } = this.context;
            if (onChange) {
                onChange(e);
            }
            if (radioGroup && radioGroup.onChange) {
                radioGroup.onChange(e);
            }
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { radioGroup } = this.context;
        return (!shallowEqual(this.props, nextProps) ||
            !shallowEqual(this.state, nextState) ||
            !shallowEqual(radioGroup, nextContext.radioGroup));
    }
    focus() {
        this.rcCheckbox.focus();
    }
    blur() {
        this.rcCheckbox.blur();
    }
    render() {
        const { props, context } = this;
        const { prefixCls: customizePrefixCls, className, children, style, ...restProps } = props;
        const prefixCls = getPrefixCls('radio', customizePrefixCls);
        const { radioGroup } = context;
        const radioProps = { ...restProps };
        if (radioGroup) {
            radioProps.name = radioGroup.name;
            radioProps.onChange = this.onChange;
            radioProps.checked = props.value === radioGroup.value;
            radioProps.disabled = props.disabled || radioGroup.disabled;
        }
        const wrapperClassString = classNames(className, {
            [`${prefixCls}-wrapper`]: true,
            [`${prefixCls}-wrapper-checked`]: radioProps.checked,
            [`${prefixCls}-wrapper-disabled`]: radioProps.disabled,
        });
        return (<label className={wrapperClassString} style={style} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <RcCheckbox {...radioProps} prefixCls={prefixCls} ref={this.saveCheckbox}/>
        {children !== undefined ? <span>{children}</span> : null}
      </label>);
    }
}
Radio.displayName = 'Radio';
Radio.defaultProps = {
    type: 'radio',
};
Radio.contextTypes = {
    radioGroup: PropTypes.any,
};
