import React, { Children, Component, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Icon from '../icon';
import Ripple from '../ripple';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class Button extends Component {
    constructor(props) {
        super(props);
        this.handleClick = e => {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => this.setState({ clicked: false }), 500);
            const { onClick } = this.props;
            if (onClick) {
                onClick(e);
            }
        };
        this.state = {
            loading: props.loading,
            clicked: false,
        };
    }
    componentWillReceiveProps(nextProps) {
        const { loading: currentLoading } = this.props;
        const { loading } = nextProps;
        if (currentLoading) {
            clearTimeout(this.delayTimeout);
        }
        if (typeof loading !== 'boolean' && loading && loading.delay) {
            this.delayTimeout = window.setTimeout(() => this.setState({ loading }), loading.delay);
        }
        else {
            this.setState({ loading });
        }
    }
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.delayTimeout) {
            clearTimeout(this.delayTimeout);
        }
    }
    render() {
        const { prefixCls: customizePrefixCls, type, shape, size, className, htmlType, children, icon, ghost, funcType, ...others } = this.props;
        const { loading, clicked } = this.state;
        const prefixCls = getPrefixCls('btn', customizePrefixCls);
        // large => lg
        // small => sm
        let sizeCls = '';
        switch (size) {
            case Size.large:
                sizeCls = 'lg';
                break;
            case Size.small:
                sizeCls = 'sm';
                break;
            default:
        }
        const ComponentProp = others.href ? 'a' : 'button';
        const classes = classNames(prefixCls, className, {
            [`${prefixCls}-${type}`]: type,
            [`${prefixCls}-${shape}`]: shape,
            [`${prefixCls}-${sizeCls}`]: sizeCls,
            [`${prefixCls}-icon-only`]: !children && icon,
            [`${prefixCls}-loading`]: loading,
            [`${prefixCls}-clicked`]: clicked,
            [`${prefixCls}-background-ghost`]: ghost,
            [`${prefixCls}-${funcType}`]: funcType,
        });
        let iconNode = icon ? <Icon type={icon}/> : null;
        iconNode = loading ? (<div className="btn-loading">
        <span className="dot1"/>
        <span className="dot2"/>
        <span className="dot3"/>
      </div>) : (iconNode);
        const kids = children || children === 0
            ? Children.map(children, child => {
                if (typeof child === 'string') {
                    return <span>{child}</span>;
                }
                return child;
            })
            : null;
        return (<Ripple disabled={others.disabled}>
        <ComponentProp {...omit(others, ['loading'])} 
        // 如果没有href属性，则表示组件使用button标签，type为'submit' | 'reset' | 'button'
        type={others.href ? undefined : htmlType || 'button'} className={classes} onClick={this.handleClick}>
          {iconNode}
          {kids}
        </ComponentProp>
      </Ripple>);
    }
}
Button.displayName = 'Button';
Button.__ANT_BUTTON = true;
Button.defaultProps = {
    loading: false,
    ghost: false,
    funcType: 'flat',
};
Button.propTypes = {
    type: PropTypes.string,
    shape: PropTypes.oneOf(['circle', 'circle-outline']),
    size: PropTypes.oneOf([Size.large, Size.default, Size.small]),
    htmlType: PropTypes.oneOf(['submit', 'button', 'reset']),
    onClick: PropTypes.func,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    className: PropTypes.string,
    icon: PropTypes.string,
    ghost: PropTypes.bool,
    funcType: PropTypes.oneOf(['raised', 'flat']),
};
