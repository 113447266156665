import React, { Component } from 'react';
import classNames from 'classnames';
import shallowequal from 'lodash/isEqual';
import Icon from '../icon';
import RcMention, { getMentions, Nav, toEditorState, toString, } from '../rc-components/editor-mention';
import { getPrefixCls } from '../configure';
export default class Mention extends Component {
    constructor(props) {
        super(props);
        this.onSearchChange = (value, prefix) => {
            const { onSearchChange } = this.props;
            if (onSearchChange) {
                return onSearchChange(value, prefix);
            }
            return this.defaultSearchChange(value);
        };
        this.onFocus = (ev) => {
            this.setState({
                focus: true,
            });
            const { onFocus } = this.props;
            if (onFocus) {
                onFocus(ev);
            }
        };
        this.onBlur = (ev) => {
            this.setState({
                focus: false,
            });
            const { onBlur } = this.props;
            if (onBlur) {
                onBlur(ev);
            }
        };
        this.focus = () => {
            this.mentionEle._editor.focusEditor();
        };
        this.mentionRef = (ele) => {
            this.mentionEle = ele;
        };
        this.state = {
            suggestions: props.suggestions,
            focus: false,
        };
    }
    componentWillReceiveProps(nextProps) {
        const { suggestions } = nextProps;
        const { props } = this;
        if (!shallowequal(suggestions, props.suggestions)) {
            this.setState({
                suggestions,
            });
        }
    }
    defaultSearchChange(value) {
        const searchValue = value.toLowerCase();
        const { suggestions } = this.props;
        const filteredSuggestions = (suggestions || []).filter(suggestion => {
            if (suggestion.type && suggestion.type === Nav) {
                return suggestion.props.value
                    ? suggestion.props.value.toLowerCase().indexOf(searchValue) !== -1
                    : true;
            }
            return suggestion.toLowerCase().indexOf(searchValue) !== -1;
        });
        this.setState({
            suggestions: filteredSuggestions,
        });
    }
    render() {
        const { className = '', prefixCls: customizePrefixCls, loading, placement, notFoundContent, onChange, } = this.props;
        const prefixCls = getPrefixCls('mention', customizePrefixCls);
        const { suggestions, focus } = this.state;
        const cls = classNames(className, {
            [`${prefixCls}-active`]: focus,
            [`${prefixCls}-placement-top`]: placement === 'top',
        });
        const notFound = loading ? <Icon type="loading"/> : notFoundContent;
        return (<RcMention {...this.props} prefixCls={prefixCls} className={cls} ref={this.mentionRef} onSearchChange={this.onSearchChange} onChange={onChange} onFocus={this.onFocus} onBlur={this.onBlur} suggestions={suggestions} notFoundContent={notFound}/>);
    }
}
Mention.displayName = 'Mention';
Mention.getMentions = getMentions;
Mention.defaultProps = {
    notFoundContent: '无匹配结果，轻敲空格完成输入',
    loading: false,
    multiLines: false,
    placement: 'bottom',
};
Mention.Nav = Nav;
Mention.toString = toString;
Mention.toContentState = toEditorState;
