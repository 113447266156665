import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip';
import { Item } from '../rc-components/menu';
class MenuItem extends Component {
    constructor() {
        super(...arguments);
        this.onKeyDown = e => {
            this.menuItem.onKeyDown(e);
        };
        this.saveMenuItem = (menuItem) => {
            this.menuItem = menuItem;
        };
    }
    render() {
        const { inlineCollapsed } = this.context;
        const props = this.props;
        const item = <Item {...props} ref={this.saveMenuItem}/>;
        if (inlineCollapsed && props.level === 1) {
            return (<Tooltip title={props.children} placement="right" overlayClassName={`${props.rootPrefixCls}-inline-collapsed-tooltip`}>
          {item}
        </Tooltip>);
        }
        return item;
    }
}
MenuItem.contextTypes = {
    inlineCollapsed: PropTypes.bool,
};
MenuItem.isMenuItem = 1;
export default MenuItem;
