import * as tslib_1 from "tslib";
import React, { Children, Component } from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { Size } from '../_util/enum';
import Grid from './Grid';
import Meta from './Meta';
import Tabs from '../tabs';
import { throttleByAnimationFrameDecorator } from '../_util/throttleByAnimationFrame';
import warning from '../_util/warning';
import addEventListener from '../_util/addEventListener';
import { getPrefixCls } from '../configure';
export { CardGridProps } from './Grid';
export { CardMetaProps } from './Meta';
export default class Card extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            widerPadding: false,
        };
        this.onTabChange = (key) => {
            const { onTabChange } = this.props;
            if (onTabChange) {
                onTabChange(key);
            }
        };
        this.saveRef = (node) => {
            this.container = node;
        };
    }
    componentDidMount() {
        this.updateWiderPadding();
        this.resizeEvent = addEventListener(window, 'resize', this.updateWiderPadding);
        if ('noHovering' in this.props) {
            const { noHovering } = this.props;
            warning(!noHovering, '`noHovering` of Card is deperated, you can remove it safely or use `hoverable` instead.');
            warning(!!noHovering, '`noHovering={false}` of Card is deperated, use `hoverable` instead.');
        }
    }
    componentWillUnmount() {
        if (this.resizeEvent) {
            this.resizeEvent.remove();
        }
        this.updateWiderPadding.cancel();
    }
    updateWiderPadding() {
        if (!this.container) {
            return;
        }
        // 936 is a magic card width pixer number indicated by designer
        const WIDTH_BOUDARY_PX = 936;
        const { widerPadding } = this.state;
        if (this.container.offsetWidth >= WIDTH_BOUDARY_PX && !widerPadding) {
            this.setState({ widerPadding: true }, () => {
                this.updateWiderPaddingCalled = true; // first render without css transition
            });
        }
        if (this.container.offsetWidth < WIDTH_BOUDARY_PX && widerPadding) {
            this.setState({ widerPadding: false }, () => {
                this.updateWiderPaddingCalled = true; // first render without css transition
            });
        }
    }
    isContainGrid() {
        const { children } = this.props;
        return Children.toArray(children).some((element) => element && element.type && element.type === Grid);
    }
    getAction(actions) {
        if (!actions || !actions.length) {
            return null;
        }
        const actionList = actions.map((action, index) => (<li style={{ width: `${100 / actions.length}%` }} key={`action-${String(index)}`}>
        <span>{action}</span>
      </li>));
        return actionList;
    }
    // For 2.x compatible
    getCompatibleHoverable() {
        const { noHovering, hoverable } = this.props;
        if ('noHovering' in this.props) {
            return !noHovering || hoverable;
        }
        return !!hoverable;
    }
    render() {
        const { prefixCls: customizePrefixCls, className, extra, bodyStyle, title, loading, bordered = true, type, cover, actions, tabList, children, activeTabKey, defaultActiveTabKey, onHeadClick, ...others } = this.props;
        const { widerPadding } = this.state;
        const prefixCls = getPrefixCls('card', customizePrefixCls);
        const classString = classNames(prefixCls, className, {
            [`${prefixCls}-loading`]: loading,
            [`${prefixCls}-bordered`]: bordered,
            [`${prefixCls}-hoverable`]: this.getCompatibleHoverable(),
            [`${prefixCls}-wider-padding`]: widerPadding,
            [`${prefixCls}-padding-transition`]: this.updateWiderPaddingCalled,
            [`${prefixCls}-contain-grid`]: this.isContainGrid(),
            [`${prefixCls}-contain-tabs`]: tabList && tabList.length,
            [`${prefixCls}-type-${type}`]: !!type,
        });
        const loadingBlock = (<div className={`${prefixCls}-loading-content`}>
        <p className={`${prefixCls}-loading-block`} style={{ width: '94%' }}/>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '28%' }}/>
          <span className={`${prefixCls}-loading-block`} style={{ width: '62%' }}/>
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '22%' }}/>
          <span className={`${prefixCls}-loading-block`} style={{ width: '66%' }}/>
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '56%' }}/>
          <span className={`${prefixCls}-loading-block`} style={{ width: '39%' }}/>
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '21%' }}/>
          <span className={`${prefixCls}-loading-block`} style={{ width: '15%' }}/>
          <span className={`${prefixCls}-loading-block`} style={{ width: '40%' }}/>
        </p>
      </div>);
        const hasActiveTabKey = activeTabKey !== undefined;
        const extraProps = {
            [hasActiveTabKey ? 'activeKey' : 'defaultActiveKey']: hasActiveTabKey
                ? activeTabKey
                : defaultActiveTabKey,
        };
        let head;
        const tabs = tabList && tabList.length ? (<Tabs {...extraProps} className={`${prefixCls}-head-tabs`} size={Size.large} onChange={this.onTabChange}>
          {tabList.map(item => (<Tabs.TabPane tab={item.tab} key={item.key}/>))}
        </Tabs>) : null;
        if (title || extra || tabs) {
            head = (<div className={`${prefixCls}-head`} onClick={onHeadClick}>
          <div className={`${prefixCls}-head-wrapper`}>
            {title && <div className={`${prefixCls}-head-title`}>{title}</div>}
            {extra && <div className={`${prefixCls}-extra`}>{extra}</div>}
          </div>
          {tabs}
        </div>);
        }
        const coverDom = cover ? <div className={`${prefixCls}-cover`}>{cover}</div> : null;
        const body = (<div className={`${prefixCls}-body`} style={bodyStyle}>
        {loading ? loadingBlock : children}
      </div>);
        const actionDom = actions && actions.length ? (<ul className={`${prefixCls}-actions`}>{this.getAction(actions)}</ul>) : null;
        const divProps = omit(others, ['onTabChange', 'noHovering', 'hoverable']);
        return (<div {...divProps} className={classString} ref={this.saveRef}>
        {head}
        {coverDom}
        {body}
        {actionDom}
      </div>);
    }
}
Card.displayName = 'Card';
Card.Grid = Grid;
Card.Meta = Meta;
tslib_1.__decorate([
    throttleByAnimationFrameDecorator()
], Card.prototype, "updateWiderPadding", null);
