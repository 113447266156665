export var FormLayout;
(function (FormLayout) {
    FormLayout["horizontal"] = "horizontal";
    FormLayout["inline"] = "inline";
    FormLayout["vertical"] = "vertical";
})(FormLayout || (FormLayout = {}));
export var FormItemValidateStatus;
(function (FormItemValidateStatus) {
    FormItemValidateStatus["success"] = "success";
    FormItemValidateStatus["warning"] = "warning";
    FormItemValidateStatus["error"] = "error";
    FormItemValidateStatus["validating"] = "validating";
})(FormItemValidateStatus || (FormItemValidateStatus = {}));
