import { Children, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import interopDefault from '../_util/interopDefault';
import { changeConfirmLocale } from '../modal/locale';
function setMomentLocale(locale) {
    if (locale && locale.locale) {
        interopDefault(moment).locale(locale.locale);
    }
    else {
        interopDefault(moment).locale('en');
    }
}
export default class LocaleProvider extends Component {
    getChildContext() {
        const { locale } = this.props;
        return {
            c7nLocale: {
                ...locale,
                exist: true,
            },
        };
    }
    componentWillMount() {
        const { locale } = this.props;
        setMomentLocale(locale);
        this.componentDidUpdate();
    }
    componentWillReceiveProps(nextProps) {
        const { locale } = this.props;
        const nextLocale = nextProps.locale;
        if (locale !== nextLocale) {
            setMomentLocale(nextProps.locale);
        }
    }
    componentDidUpdate() {
        const { locale } = this.props;
        changeConfirmLocale(locale && locale.Modal);
    }
    componentWillUnmount() {
        changeConfirmLocale();
    }
    render() {
        const { children } = this.props;
        return Children.only(children);
    }
}
LocaleProvider.propTypes = {
    locale: PropTypes.object,
};
LocaleProvider.defaultProps = {
    locale: {},
};
LocaleProvider.childContextTypes = {
    c7nLocale: PropTypes.object,
};
