import { observable, runInAction } from 'mobx';
import { RecordStatus } from 'choerodon-ui/pro/lib/data-set/enum';
import message from 'choerodon-ui/pro/lib/message';
import exception from 'choerodon-ui/pro/lib/_util/exception';
import { $l } from 'choerodon-ui/pro/lib/locale-context';
const defaultFeedback = {
    loadSuccess() { },
    loadFailed(error) {
        message.error(exception(error, $l('DataSet', 'query_failure')));
    },
    submitSuccess() {
        message.success($l('DataSet', 'submit_success'));
    },
    submitFailed(error) {
        message.error(exception(error, $l('DataSet', 'submit_failure')));
    },
};
const globalConfig = observable.map([
    ['prefixCls', 'c7n'],
    ['proPrefixCls', 'c7n-pro'],
    ['ripple', true],
    ['lookupUrl', code => `/common/code/${code}/`],
    ['lookupAxiosMethod', 'post'],
    ['lovDefineUrl', code => `/sys/lov/lov_define?code=${code}`],
    ['lovQueryUrl', code => `/common/lov/dataset/${code}`],
    ['dataKey', 'rows'],
    ['totalKey', 'total'],
    ['statusKey', '__status'],
    ['tlsKey', '__tls'],
    [
        'status',
        { [RecordStatus.add]: 'add', [RecordStatus.update]: 'update', [RecordStatus.delete]: 'delete' },
    ],
    ['labelLayout', 'horizontal'],
    ['queryBar', 'normal'],
    ['tableBorder', true],
    ['tableHighLightRow', true],
    ['tableRowHeight', 30],
    ['tableColumnResizable', true],
    ['modalSectionBorder', true],
    ['modalOkFirst', true],
    ['feedback', defaultFeedback],
]);
export function getConfig(key) {
    // FIXME: observable.map把构建map时传入的key类型和value类型分别做了union，
    // 丢失了一一对应的映射关系，导致函数调用者无法使用union后的返回值类型，因此需要指定本函数返回值为any
    return globalConfig.get(key);
}
export function getPrefixCls(suffixCls, customizePrefixCls) {
    if (customizePrefixCls) {
        return customizePrefixCls;
    }
    return `${getConfig('prefixCls')}-${suffixCls}`;
}
export function getProPrefixCls(suffixCls, customizePrefixCls) {
    if (customizePrefixCls) {
        return customizePrefixCls;
    }
    return `${getConfig('proPrefixCls')}-${suffixCls}`;
}
export default function configure(config) {
    runInAction(() => {
        Object.keys(config).forEach((key) => globalConfig.set(key, config[key]));
    });
}
