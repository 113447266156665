import React from 'react';
import FilterSelect from './FilterSelect';
import ColumnFilter from './ColumnFilter';
export default function FilterBar(props) {
    const { prefixCls, columns, onColumnFilterChange, onFilterSelectChange, onFilterSelectClear, onFilter, dataSource, filters, columnFilters, placeholder, multiple, getPopupContainer, } = props;
    return (<div className={`${prefixCls}-filter-bar`}>
      <FilterSelect prefixCls={prefixCls} placeholder={placeholder} columns={columns} dataSource={dataSource} onChange={onFilterSelectChange} onClear={onFilterSelectClear} onFilter={onFilter} filters={filters} columnFilters={columnFilters} getPopupContainer={getPopupContainer} multiple={multiple}/>
      <ColumnFilter prefixCls={prefixCls} columns={columns} onColumnFilterChange={onColumnFilterChange} getPopupContainer={getPopupContainer}/>
    </div>);
}
