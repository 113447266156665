import { cloneElement, Component, createElement, isValidElement, } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import { findChildInChildrenByKey, findShownChildInChildrenByKey, isSameChildren, mergeChildren, toArrayChildren, } from './ChildrenUtils';
import AnimateChild from './AnimateChild';
import animUtil from './util';
const defaultKey = `animate_${Date.now()}`;
function getChildrenFromProps(props) {
    const { children } = props;
    if (isValidElement(children)) {
        if (!children.key) {
            return cloneElement(children, {
                key: defaultKey,
            });
        }
    }
    return children;
}
export default class Animate extends Component {
    constructor() {
        super(...arguments);
        this.currentlyAnimatingKeys = {};
        this.keysToEnter = [];
        this.keysToLeave = [];
        this.state = {
            children: toArrayChildren(getChildrenFromProps(this.props)),
        };
        this.childrenRefs = {};
        this.performEnter = (key) => {
            if (this.childrenRefs[key]) {
                this.currentlyAnimatingKeys[key] = true;
                this.childrenRefs[key].componentWillEnter(this.handleDoneAdding.bind(this, key, 'enter'));
            }
        };
        this.performAppear = (key) => {
            if (this.childrenRefs[key]) {
                this.currentlyAnimatingKeys[key] = true;
                this.childrenRefs[key].componentWillAppear(this.handleDoneAdding.bind(this, key, 'appear'));
            }
        };
        this.handleDoneAdding = (key, type) => {
            const { props } = this;
            const { exclusive, onAppear = noop, onEnd = noop, onEnter = noop } = props;
            delete this.currentlyAnimatingKeys[key];
            if (exclusive && props !== this.nextProps) {
                return;
            }
            if (!this.isValidChildByKey(toArrayChildren(getChildrenFromProps(props)), key)) {
                this.performLeave(key);
            }
            else if (type === 'appear') {
                if (animUtil.allowAppearCallback(props)) {
                    onAppear(key);
                    onEnd(key, true);
                }
            }
            else if (animUtil.allowEnterCallback(props)) {
                onEnter(key);
                onEnd(key, true);
            }
        };
        this.performLeave = (key) => {
            if (this.childrenRefs[key]) {
                this.currentlyAnimatingKeys[key] = true;
                this.childrenRefs[key].componentWillLeave(this.handleDoneLeaving.bind(this, key));
            }
        };
        this.handleDoneLeaving = (key) => {
            const { props, state: { children }, } = this;
            const { exclusive, onEnd = noop, onLeave = noop, hiddenProp } = props;
            delete this.currentlyAnimatingKeys[key];
            if (exclusive && props !== this.nextProps) {
                return;
            }
            const currentChildren = toArrayChildren(getChildrenFromProps(props));
            if (this.isValidChildByKey(currentChildren, key)) {
                this.performEnter(key);
            }
            else {
                const end = () => {
                    if (animUtil.allowLeaveCallback(props)) {
                        onLeave(key);
                        onEnd(key, false);
                    }
                };
                if (!isSameChildren(children, currentChildren, hiddenProp)) {
                    this.setState({
                        children: currentChildren,
                    }, end);
                }
                else {
                    end();
                }
            }
        };
    }
    componentDidMount() {
        const { hiddenProp } = this.props;
        let { children } = this.state;
        if (hiddenProp) {
            children = children.filter(child => !child.props[hiddenProp]);
        }
        children.forEach(child => {
            if (child && child.key) {
                this.performAppear(child.key);
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        this.nextProps = nextProps;
        const nextChildren = toArrayChildren(getChildrenFromProps(nextProps));
        const { exclusive, hiddenProp } = this.props;
        const { children } = this.state;
        const currentlyAnimatingKeys = this.currentlyAnimatingKeys;
        if (exclusive) {
            Object.keys(currentlyAnimatingKeys).forEach(key => this.stop(key));
        }
        const currentChildren = exclusive
            ? toArrayChildren(getChildrenFromProps(this.props))
            : children;
        let newChildren = [];
        if (hiddenProp) {
            nextChildren.forEach(nextChild => {
                if (nextChild) {
                    let newChild;
                    const currentChild = findChildInChildrenByKey(currentChildren, nextChild.key);
                    if (nextChild.props[hiddenProp] && currentChild && !currentChild.props[hiddenProp]) {
                        newChild = cloneElement(nextChild, { [hiddenProp]: false });
                    }
                    else {
                        newChild = nextChild;
                    }
                    if (newChild) {
                        newChildren.push(newChild);
                    }
                }
            });
            newChildren = mergeChildren(currentChildren, newChildren);
        }
        else {
            newChildren = mergeChildren(currentChildren, nextChildren);
        }
        this.setState({
            children: newChildren,
        });
        nextChildren.forEach(child => {
            const key = child && child.key;
            if (key) {
                if (child && currentlyAnimatingKeys[key]) {
                    return;
                }
                const hasPrev = child && findChildInChildrenByKey(currentChildren, key);
                if (hiddenProp) {
                    const showInNext = !child.props[hiddenProp];
                    if (hasPrev) {
                        const showInNow = findShownChildInChildrenByKey(currentChildren, key, hiddenProp);
                        if (!showInNow && showInNext) {
                            this.keysToEnter.push(key);
                        }
                    }
                    else if (showInNext) {
                        this.keysToEnter.push(key);
                    }
                }
                else if (!hasPrev) {
                    this.keysToEnter.push(key);
                }
            }
        });
        currentChildren.forEach(child => {
            const key = child && child.key;
            if (key) {
                if (child && currentlyAnimatingKeys[key]) {
                    return;
                }
                const hasNext = child && findChildInChildrenByKey(nextChildren, key);
                if (hiddenProp) {
                    const showInNow = !child.props[hiddenProp];
                    if (hasNext) {
                        const showInNext = findShownChildInChildrenByKey(nextChildren, key, hiddenProp);
                        if (!showInNext && showInNow) {
                            this.keysToLeave.push(key);
                        }
                    }
                    else if (showInNow) {
                        this.keysToLeave.push(key);
                    }
                }
                else if (!hasNext) {
                    this.keysToLeave.push(key);
                }
            }
        });
    }
    componentDidUpdate() {
        const keysToEnter = this.keysToEnter;
        this.keysToEnter = [];
        keysToEnter.forEach(this.performEnter);
        const keysToLeave = this.keysToLeave;
        this.keysToLeave = [];
        keysToLeave.forEach(this.performLeave);
    }
    isValidChildByKey(currentChildren, key) {
        const { hiddenProp } = this.props;
        if (hiddenProp) {
            return !!findShownChildInChildrenByKey(currentChildren, key, hiddenProp);
        }
        return !!findChildInChildrenByKey(currentChildren, key);
    }
    stop(key) {
        delete this.currentlyAnimatingKeys[key];
        const component = this.childrenRefs[key];
        if (component) {
            component.stop();
        }
    }
    render() {
        const { props } = this;
        this.nextProps = props;
        const { animation, transitionName, transitionEnter, transitionAppear, transitionLeave, component: Cmp, componentProps, ...otherProps } = props;
        const { children: stateChildren } = this.state;
        let children = [];
        if (stateChildren) {
            children = stateChildren.map(child => {
                if (child === null || child === undefined) {
                    return child;
                }
                if (!child.key) {
                    throw new Error('must set key for animate children');
                }
                return createElement(AnimateChild, {
                    key: child.key,
                    ref: node => {
                        if (child.key) {
                            this.childrenRefs[child.key] = node;
                        }
                    },
                    animation,
                    transitionName,
                    transitionEnter,
                    transitionAppear,
                    transitionLeave,
                }, child);
            });
        }
        if (Cmp) {
            const passedProps = omit(otherProps, [
                'exclusive',
                'onEnd',
                'onEnter',
                'onLeave',
                'onAppear',
                'hiddenProp',
            ]);
            return createElement(Cmp, {
                ...passedProps,
                ...componentProps,
            }, children);
        }
        return children[0] || null;
    }
}
Animate.displayName = 'Animate';
Animate.propTypes = {
    component: PropTypes.any,
    componentProps: PropTypes.object,
    animation: PropTypes.object,
    transitionName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    transitionEnter: PropTypes.bool,
    transitionAppear: PropTypes.bool,
    exclusive: PropTypes.bool,
    transitionLeave: PropTypes.bool,
    onEnd: PropTypes.func,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onAppear: PropTypes.func,
    hiddenProp: PropTypes.string,
};
Animate.defaultProps = {
    animation: {},
    component: 'span',
    componentProps: {},
    transitionEnter: true,
    transitionLeave: true,
    transitionAppear: false,
};
