import React, { Children, cloneElement, Component } from 'react';
import classNames from 'classnames';
import warning from '../_util/warning';
import RcDropdown from '../rc-components/dropdown';
import { getPrefixCls } from '../configure';
export default class Dropdown extends Component {
    getTransitionName() {
        const { placement = '', transitionName } = this.props;
        if (transitionName !== undefined) {
            return transitionName;
        }
        if (placement.indexOf('top') >= 0) {
            return 'slide-down';
        }
        return 'slide-up';
    }
    componentDidMount() {
        const { overlay } = this.props;
        const overlayProps = overlay.props;
        warning(!overlayProps.mode || overlayProps.mode === 'vertical', `mode="${overlayProps.mode}" is not supported for Dropdown's Menu.`);
    }
    render() {
        const { children, prefixCls: customizePrefixCls, overlay: overlayElements, trigger, disabled, } = this.props;
        const prefixCls = getPrefixCls('dropdown', customizePrefixCls);
        const child = Children.only(children);
        const overlay = Children.only(overlayElements);
        const dropdownTrigger = cloneElement(child, {
            className: classNames(child.props.className, `${prefixCls}-trigger`),
            disabled,
        });
        // menu cannot be selectable in dropdown defaultly
        const selectable = overlay.props.selectable || false;
        const fixedModeOverlay = cloneElement(overlay, {
            mode: 'vertical',
            selectable,
        });
        return (<RcDropdown {...this.props} prefixCls={prefixCls} transitionName={this.getTransitionName()} trigger={disabled ? [] : trigger} overlay={fixedModeOverlay}>
        {dropdownTrigger}
      </RcDropdown>);
    }
}
Dropdown.displayName = 'Dropdown';
Dropdown.defaultProps = {
    mouseEnterDelay: 0.15,
    mouseLeaveDelay: 0.1,
    placement: 'bottomLeft',
};
