import React, { Component } from 'react';
import Tooltip from '../tooltip';
import warning from '../_util/warning';
import { getPrefixCls } from '../configure';
export default class Popover extends Component {
    constructor() {
        super(...arguments);
        this.saveTooltip = (node) => {
            this.tooltip = node;
        };
    }
    getPopupDomNode() {
        return this.tooltip.getPopupDomNode();
    }
    getOverlay() {
        const { title, content } = this.props;
        const prefixCls = this.getPrefixCls();
        warning(!('overlay' in this.props), 'Popover[overlay] is removed, please use Popover[content] instead');
        return (<div>
        {title && <div className={`${prefixCls}-title`}>{title}</div>}
        <div className={`${prefixCls}-inner-content`}>{content}</div>
      </div>);
    }
    getPrefixCls() {
        const { prefixCls } = this.props;
        return getPrefixCls('popover', prefixCls);
    }
    render() {
        const props = { ...this.props };
        delete props.title;
        return (<Tooltip {...props} prefixCls={this.getPrefixCls()} ref={this.saveTooltip} overlay={this.getOverlay()}/>);
    }
}
Popover.displayName = 'Popover';
Popover.defaultProps = {
    placement: 'top',
    transitionName: 'zoom-big',
    trigger: 'hover',
    mouseEnterDelay: 0.1,
    mouseLeaveDelay: 0.1,
    overlayStyle: {},
};
