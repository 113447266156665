import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RcSteps, { Step } from '../rc-components/steps';
import { getPrefixCls } from '../configure';
export default class Steps extends Component {
    render() {
        const { props } = this;
        return <RcSteps {...props} prefixCls={getPrefixCls('steps', props.prefixCls)}/>;
    }
}
Steps.displayName = 'Steps';
Steps.Step = Step;
Steps.defaultProps = {
    iconPrefix: 'icon',
    current: 0,
};
Steps.propTypes = {
    prefixCls: PropTypes.string,
    iconPrefix: PropTypes.string,
    current: PropTypes.number,
};
