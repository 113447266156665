import React, { Component } from 'react';
import classNames from 'classnames';
import RcInputNumber from '../rc-components/input-number';
import { Size } from '../_util/enum';
import { getPrefixCls } from '../configure';
export default class InputNumber extends Component {
    render() {
        const { className, size, prefixCls: customizePrefixCls, ...others } = this.props;
        const prefixCls = getPrefixCls('input-number', customizePrefixCls);
        const inputNumberClass = classNames({
            [`${prefixCls}-lg`]: size === Size.large,
            [`${prefixCls}-sm`]: size === Size.small,
        }, className);
        return (<RcInputNumber ref={(c) => (this.inputNumberRef = c)} className={inputNumberClass} prefixCls={prefixCls} {...others}/>);
    }
    focus() {
        this.inputNumberRef.focus();
    }
    blur() {
        this.inputNumberRef.blur();
    }
}
InputNumber.displayName = 'InputNumber';
InputNumber.defaultProps = {
    step: 1,
};
