function on(eventName, fn, el, useCapture) {
    if (el) {
        if (el.addEventListener) {
            el.addEventListener(eventName, fn, useCapture);
        }
        else if (el.attachEvent) {
            el.attachEvent(`on${eventName}`, fn);
        }
    }
}
function off(eventName, fn, el, useCapture) {
    if (el) {
        if (el.removeEventListener) {
            el.removeEventListener(eventName, fn, useCapture);
        }
        else if (el.attachEvent) {
            el.detachEvent(`on${eventName}`, fn);
        }
    }
}
export default class EventManager {
    constructor(el) {
        this.events = {};
        this.el = el;
    }
    addEventListener(eventName, fn, useCapture = false) {
        eventName = eventName.toLowerCase();
        const events = this.events[eventName] || [];
        const index = events.findIndex(([event]) => event === fn);
        if (index === -1) {
            events.push([fn, useCapture]);
            this.events[eventName] = events;
            on(eventName, fn, this.el, useCapture);
        }
        return this;
    }
    removeEventListener(eventName, fn, useCapture = false) {
        eventName = eventName.toLowerCase();
        const events = this.events[eventName];
        if (events) {
            if (fn) {
                const index = events.findIndex(([event]) => event === fn);
                if (index !== -1) {
                    events.splice(index, 1);
                }
                off(eventName, fn, this.el, useCapture);
            }
            else {
                this.events[eventName] = this.el
                    ? (this.events[eventName] || []).filter(([event, capture]) => {
                        off(eventName, event, this.el, capture);
                        return false;
                    })
                    : [];
            }
        }
        return this;
    }
    fireEvent(eventName, ...rest) {
        const events = this.events[eventName.toLowerCase()];
        return events
            ? Promise.all(events.map(([fn]) => fn(...rest))).then(all => all.every(result => result !== false))
            : Promise.resolve(true);
    }
    clear() {
        if (this.el) {
            Object.keys(this.events).forEach(eventName => this.removeEventListener(eventName));
        }
        this.events = {};
        return this;
    }
}
export function preventDefault(e) {
    e.preventDefault();
}
export function stopPropagation(e) {
    e.stopPropagation();
}
export function stopEvent(e) {
    preventDefault(e);
    stopPropagation(e);
}
