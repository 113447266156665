import React, { Children, cloneElement, Component, } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import Icon from '../icon';
import warning from '../_util/warning';
import isFlexSupported from '../_util/isFlexSupported';
import RcTabs, { TabContent, TabPane } from '../rc-components/tabs';
import ScrollableInkTabBar from '../rc-components/tabs/ScrollableInkTabBar';
import { generateKey } from '../rc-components/tabs/utils';
import { Size } from '../_util/enum';
import { TabsPosition, TabsType } from './enum';
import { getPrefixCls } from '../configure';
export default class Tabs extends Component {
    constructor() {
        super(...arguments);
        this.createNewTab = targetKey => {
            const { onEdit } = this.props;
            if (onEdit) {
                onEdit(targetKey, 'add');
            }
        };
        this.removeTab = (targetKey, e) => {
            e.stopPropagation();
            if (!targetKey) {
                return;
            }
            const { onEdit } = this.props;
            if (onEdit) {
                onEdit(targetKey, 'remove');
            }
        };
        this.handleChange = (activeKey) => {
            const { onChange } = this.props;
            if (onChange) {
                onChange(activeKey);
            }
        };
    }
    componentDidMount() {
        const NO_FLEX = ' no-flex';
        const tabNode = findDOMNode(this);
        if (tabNode && !isFlexSupported() && tabNode.className.indexOf(NO_FLEX) === -1) {
            tabNode.className += NO_FLEX;
        }
    }
    render() {
        const { prefixCls: customizePrefixCls, className = '', size, type = TabsType.line, tabPosition, children, tabBarStyle, hideAdd, onTabClick, onPrevClick, onNextClick, animated = true, tabBarGutter, } = this.props;
        let { tabBarExtraContent } = this.props;
        const prefixCls = getPrefixCls('tabs', customizePrefixCls);
        const inkBarAnimated = typeof animated === 'object' ? animated.inkBar : animated;
        let tabPaneAnimated = typeof animated === 'object' ? animated.tabPane : animated;
        // card tabs should not have animation
        if (type !== TabsType.line) {
            tabPaneAnimated = 'animated' in this.props ? tabPaneAnimated : false;
        }
        const isCard = type === TabsType.card || type === TabsType['editable-card'];
        warning(!(isCard && (size === Size.small || size === Size.large)), "Tabs[type=card|editable-card] doesn't have small or large size, it's by designed.");
        const cls = classNames(className, `${prefixCls}-${type}`, {
            [`${prefixCls}-vertical`]: tabPosition === TabsPosition.left || tabPosition === TabsPosition.right,
            [`${prefixCls}-${size}`]: !!size,
            [`${prefixCls}-card`]: isCard,
            [`${prefixCls}-no-animation`]: !tabPaneAnimated,
        });
        // only card type tabs can be added and closed
        let childrenWithClose = [];
        if (type === TabsType['editable-card']) {
            childrenWithClose = [];
            Children.forEach(children, (child, index) => {
                let closable = child.props.closable;
                closable = typeof closable === 'undefined' ? true : closable;
                const closeIcon = closable ? (<Icon type="close" onClick={e => this.removeTab(child.key, e)}/>) : null;
                childrenWithClose.push(cloneElement(child, {
                    tab: (<div className={closable ? undefined : `${prefixCls}-tab-unclosable`}>
                {child.props.tab}
                {closeIcon}
              </div>),
                    key: generateKey(child.key, index),
                }));
            });
            // Add new tab handler
            if (!hideAdd) {
                tabBarExtraContent = (<span>
            <Icon type="plus" className={`${prefixCls}-new-tab`} onClick={this.createNewTab}/>
            {tabBarExtraContent}
          </span>);
            }
        }
        tabBarExtraContent = tabBarExtraContent ? (<div className={`${prefixCls}-extra-content`}>{tabBarExtraContent}</div>) : null;
        const renderTabBar = () => (<ScrollableInkTabBar inkBarAnimated={inkBarAnimated} extraContent={tabBarExtraContent} onTabClick={onTabClick} onPrevClick={onPrevClick} onNextClick={onNextClick} style={tabBarStyle} tabBarGutter={tabBarGutter}/>);
        return (<RcTabs {...this.props} prefixCls={prefixCls} className={cls} tabBarPosition={tabPosition} renderTabBar={renderTabBar} renderTabContent={() => <TabContent animated={tabPaneAnimated} animatedWithMargin/>} onChange={this.handleChange}>
        {childrenWithClose.length > 0 ? childrenWithClose : children}
      </RcTabs>);
    }
}
Tabs.displayName = 'Tabs';
Tabs.TabPane = TabPane;
Tabs.defaultProps = {
    hideAdd: false,
};
